import { StarOutline } from "@mui/icons-material/";
import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

export function CreditBlock(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {!isObjectEmpty(dataObj?.creditBalance) && (
        <Tooltip title={"Бонусы"}>
          <Box
            onClick={() => navigate("/credits")}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "nowrap",
              margin: "0px 14px",
              cursor: "pointer",
            }}
            xs={2}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "2px",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderLeft: "1px solid",
                borderRadius: "0.4rem 0px 0px 0.4rem",
                height: "30px",
              }}
            >
              <StarOutline sx={{ fontSize: "1.1rem" }} />
            </Box>
            <Typography
              sx={{
                padding: "2px",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
                borderRadius: "0px 0.4rem 0.4rem 0px",
                height: "30px",
              }}
              variant="body1"
            >
              {dataObj?.creditBalance}
            </Typography>
          </Box>
        </Tooltip>
      )}
    </React.Fragment>
  );
}
