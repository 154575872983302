import { useMutation } from "@apollo/client";
import { REJECT_RECOMMENDATIONS } from "components/gql/gql_queries";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty } from "data/functions";

export function RejectRecsMutation() {
  const { setAlert } = GetAlertData();

  const [rejectRecommendation, rejectRecommendationVars] = useMutation(
    REJECT_RECOMMENDATIONS,
    {
      onCompleted: (data) => {
        // console.log(data.recommendationReject.message);
        if (data.recommendationReject.code === 200) {
          setAlert("Рекомендации удалены", "success");
          consoleBeauty(
            "Успешное удаление рекомендаций",
            "GraphQL",
            "darkgreen",
          );
        } else {
          consoleBeauty(
            "RejectRecs: " + data?.recommendationReject?.message,
            "GraphQL",
            "red",
          );
          sessionStorage.setItem(
            "error_" + new Date(Date.now()).toISOString(),
            "RejectRecs: " + data?.recommendationReject?.message,
          );
        }
      },
      onError: (e) => {
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          "RejectRecs: " + e.message,
        );

        consoleBeauty(e.message, "GraphQL", "red");
        setAlert("Не удалось удалить рекомендации", "error");
      },
    },
  );
  return [rejectRecommendation, rejectRecommendationVars];
}
