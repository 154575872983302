import { List } from "@mui/material";
import { NotFoundBlock } from "components/NotFoundBlock";
import { CarRecsQuery } from "components/gql/queris/GetCarRecs";
import { GetData } from "contexts/DataContext";
import { UseOnScreen, isObjectEmpty } from "data/functions";
import RecBlock from "pages/recs/RecBlock";
import React, { useEffect, useState } from "react";
import RecButtons from "./RecButtons";

export default function RecList(props) {
  const { dataObj } = GetData();
  const [recArr, setRecArr] = useState([]);
  const [recVars] = CarRecsQuery();

  useEffect(() => {
    let arr =
      (dataObj?.recsArray ? dataObj?.recsArray : {})[
        dataObj?.usedCar?.car?.id
      ] || [];
    setRecArr(arr);
  }, [dataObj?.usedCar, dataObj?.recsArray]);

  return (
    <React.Fragment>
      {!isObjectEmpty(recArr) && (
        <List
          className="single-col"
          dense
          sx={{
            width: "100%",
            background: "transparent",
            padding: "0px 2px",
          }}
        >
          {/* https://codepen.io/alvarotrigo/pen/jOZOzoq */}

          {(!isObjectEmpty(recArr) ? [...recArr] : [])
            .sort((a, b) => (a.urgency === 1 ? 1 : -1))
            .map((recItem, index) => (
              <RecBlock
                index={index}
                isMobile={dataObj?.deviceWidth?.isMobile}
                key={index}
                recItem={recItem}
              />
            ))}
        </List>
      )}

      {!recVars?.loading &&
        (!recArr === null ||
          isObjectEmpty(recArr) ||
          recArr === undefined ||
          !recArr) && <NotFoundBlock />}
    </React.Fragment>
  );
}
