import React, { useEffect, useState } from "react";

import { ExtLoginMutation } from "components/gql/mutations/ExtLogin";

import { Telegram } from "@mui/icons-material/";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Link, Typography } from "@mui/material";
import { Timer } from "components/Timer";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  formatPhone,
  getFlowFunc,
  getStrDeforeG,
  isObjectEmpty,
} from "data/functions";
import PhoneField from "pages/deal/fields/PhoneField";
import { StatefulPinInput } from "react-input-pin-code";

export const CodeBlock = (props) => {
  const { setAlert } = GetAlertData();
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const [remainingTime, setRemainingTime] = useState(30);
  const [timerCount, setTimerCount] = useState();
  const [flowID, setFlowID] = useState("");
  const [active, setActive] = useState(false);
  const [usedPhone, setUsedPhone] = useState("");
  const [loginRefetch] = ExtLoginMutation();

  async function getFlow(ch, ph = "") {
    if (!cInfo?.UUID) {
      setAlert("Не удалось получить идентификатор компании", "warning");
    }
    let [flowID, expiresIn, telegramURL] = await getFlowFunc(
      props?.flowlRefetch,
      ch,
      cInfo?.UUID,
      ph,
    );

    if (flowID) {
      // if (ch === "telegram") {
      setActive(true);
      setTimer(30);
      setFlowID(flowID);
      // setTimer(10);
      // }
      return;
    } else {
      setAlert(
        "Не удалось использовать этот метод авторизации. \nПопробуйте позже.",
        "warning",
      );
      setActive(false);
      // props?.setMethod("");
      return;
    }
  }

  const handleSubmit = () => {
    if (!isObjectEmpty(usedPhone)) {
      getFlow(props?.method, usedPhone.replace(/\D/g, ""));
      // setTimer(30);
      // setActive(true);
    }
  };

  async function checkCode(code) {
    loginRefetch({
      variables: {
        input: {
          check_code: code,
          company_uuid: cInfo?.UUID,
          flow_id: flowID,
        },
      },
    });
  }

  function setTimer(secs) {
    setTimerCount(new Date(Date.now() + secs * 1000));
  }

  return (
    <React.Fragment>
      <Box sx={{ padding: "1rem" }}>
        {!active && (
          <>
            <PhoneField setUsedPhone={setUsedPhone} usedPhone={usedPhone} />

            <LoadingButton
              fullWidth
              loading={props?.flowVars?.loading}
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
              type="submit"
              variant="contained"
            >
              Далее
            </LoadingButton>
          </>
        )}
        {active && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              margin: "2rem 0px",
            }}
          >
            <Typography
              sx={{ textAlign: "center", paddingBottom: "2rem" }}
              variant="body2"
            >
              {"Для подтверждения введите код, "}
              <br />
              {props?.method === "sms"
                ? "отправленный на телефон "
                : "состоящий из последних 4 цифр номера входящего звонка"}
            </Typography>
            <StatefulPinInput
              length={8}
              onChange={(el, ind, arr) => {
                if (arr.join("").length >= 4) {
                  checkCode(arr.join(""));
                }
              }}
              placeholder=""
              showState={false}
              size="lg"
              // validate={codeCheck}
            />
            <Box sx={{ paddingTop: "1rem" }}>
              <Timer
                expiryTimestamp={timerCount}
                method={props?.method}
                remainingTime={remainingTime}
                setMethod={props?.setMethod}
                setRemainingTime={setRemainingTime}
                variant="code"
              />
            </Box>
            {remainingTime < 1 && (
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                variant="body2"
              >
                Выслать код повторно
              </Button>
            )}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
