import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { DecodeVinMutation } from "components/gql/mutations/DecodeVin";
import { GetCarReportQuery } from "components/gql/queris/GetCarReport";
import { GetModelsQuery } from "components/gql/queris/GetModels";
import { GetData } from "contexts/DataContext";
import {
  decodePlate,
  decodeVin,
  isIdentical,
  isObjectEmpty,
  isPlateCorrect,
} from "data/functions";
import React from "react";
import DecodeGrz from "../DecodeGrz";
import DecodeSts from "../DecodeSts";
import FieldBlock from "./FieldBlock";

import { SearchMark } from "components/gql/queris/SearchMark";
import { getModels } from "./FieldModels";
import { checkVin } from "./FieldVin";

export default function FieldPlate(props) {
  const [plate, setPlate] = React.useState(props?.carAddData?.plate || "");
  const [vinQuery] = DecodeVinMutation();
  const [plateQuery] = GetCarReportQuery();
  const [searchMarkQuery] = SearchMark();
  const [modelsQuery] = GetModelsQuery();

  const { dataObj } = GetData();

  React.useEffect(() => {
    props?.setCarAddData((e) => {
      return { ...e, plate: plate };
    });
  }, [plate]);

  React.useEffect(() => {
    if (!isIdentical(plate, props?.carAddData?.plate)) {
      setPlate(props?.carAddData?.plate);
    }
  }, [props?.carAddData]);

  async function decodePlateLocal(plate) {
    if (!isPlateCorrect(plate)) {
      return;
    }
    let res = {};
    if (plate && plate.toUpperCase() === props?.carAddData?.plateReport?.grz) {
      res = props?.carAddData?.plateReport;
    } else {
      res = await checkPlate(plate, plateQuery);
    }

    let data = props?.carAddData;
    if (!isObjectEmpty(res)) {
      let mark = {};
      let models = {};
      let searchMark = await searchMarkQuery({
        variables: {
          markName: res?.brandNormalized,
        },
      });
      if (searchMark?.data && searchMark?.data?.exch_searchMark[0]?.id) {
        mark = searchMark?.data?.exch_searchMark[0];
      } else {
        let searcStr = (res?.brandNormalized ?? "").toLowerCase();
        let regex = / \([^)]*\)/gm;
        searcStr = searcStr.replace(regex, "");
        mark = (dataObj?.markList ?? []).find((element) =>
          (element?.name ?? "").toLowerCase().includes(searcStr),
        );
      }
      models = await getModels(dataObj, modelsQuery, mark?.id);
      props?.setHelperText((e) => {
        return {
          ...e,
          markText: res?.brandNormalized ? res?.brandNormalized : "",
          modelText: res?.brandOriginal ? res?.brandOriginal : "",
        };
      });
      props?.setCarAddData((e) => {
        return {
          ...e,
          mark: !isObjectEmpty(mark) ? mark : null,
          markList: dataObj?.markList ?? [],
          model: null,
          modelList: !isObjectEmpty(models) ? models : [],
          plateReport: res,
          vin: res?.vin ? res?.vin : "",
          year: res?.year ? new Date("" + res?.year) : "",
        };
      });
      if (res?.vin && !isIdentical(data?.vin, res?.vin)) {
        checkVin(res?.vin, vinQuery, dataObj);
      }
    }
  }
  return (
    <FieldBlock
      // component={
      //   <ResponseButtonWithDialog
      //     data={plateData}
      //     dialogTitle="Расшифровка гос. номера"
      //     data2={plateDecodeData}
      //     dialogTitle2="Распознанный по фото гос. номер"
      //   />
      // }
      helperText={
        props?.helperText?.plateText ? props?.helperText?.plateText : undefined
      }
      icon={
        <DecodeGrz
          setCarAddData={props?.setCarAddData}
          setHelperText={props?.setHelperText}
          checkPlate={decodePlateLocal}
        />
      }
      label="plate"
      length={12}
      onBlurFunc={decodePlateLocal}
      setValue={setPlate}
      title="Гос. номер"
      value={plate.toLowerCase()}
    />
  );
}

export async function checkPlate(p, plateQuery) {
  if (p.length < 7) return;
  // reset(false);
  let [plateRes, error] = await decodePlate(p, plateQuery);
  if (error) {
    return;
  }
  return plateRes;
}
