import { SearchOff } from "@mui/icons-material/";
import { Box } from "@mui/material";
import { hex2rgb } from "data/functions";
import * as React from "react";

export const NotFoundBlock = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "400px",
      }}
    >
      <SearchOff
        sx={{
          fontSize: "3rem",
          color: (props) =>
            hex2rgb(props.custom?.telegramColors?.text_color, 0.3),
        }}
      />
    </Box>
  );
};
