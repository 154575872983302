import { Article } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import ParamBlock from "components/ParamBlock";
import { isObjectEmpty, translateError, translateResKey } from "data/functions";
import React from "react";

export default function ResponseButtonWithDialog(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {(!isObjectEmpty(props?.data) || !isObjectEmpty(props?.data2)) && (
        <IconButton
          aria-hidden={false}
          id="sts-button-info"
          onClick={(e) => setOpen(true)}
          // sx={{ marginLeft: "12px" }}
        >
          <Article />
        </IconButton>
      )}
      <Dialog
        maxWidth="xl"
        onClose={(e) => {
          setOpen(false);
        }}
        open={open}
      >
        <DialogContent sx={{ padding: "1rem" }}>
          {!isObjectEmpty(props?.data) && (
            <React.Fragment>
              <Typography variant="h6">{props?.dialogTitle}</Typography>
              <Box sx={{ padding: "0.4rem" }}>
                <ResponseParamsBlock data={props?.data} name={"data"} />
              </Box>
            </React.Fragment>
          )}
          {!isObjectEmpty(props?.data2) && (
            <React.Fragment>
              <Typography variant="h6">{props?.dialogTitle2}</Typography>
              <Box sx={{ padding: "0.4rem" }}>
                <ResponseParamsBlock data={props?.data2} name={"data2"} />
              </Box>
            </React.Fragment>
          )}
        </DialogContent>

        <Button
          onClick={(e) => {
            setOpen(false);
          }}
          sx={{ margin: "0.4rem" }}
          variant="outlined"
        >
          ОК
        </Button>
      </Dialog>
    </React.Fragment>
  );
}

export function ResponseParamsBlock(props) {
  return (
    <React.Fragment>
      {Object.keys(props?.data).map((key, ind) => {
        if (
          !isObjectEmpty(props?.data[key]) &&
          !isObjectEmpty(translateResKey(key))
        ) {
          return (
            <Box key={props?.name + ind}>
              <ParamBlock
                text={
                  Array.isArray(props?.data[key])
                    ? props?.data[key].join("; ")
                    : translateError(props?.data[key]?.toString()) !== ""
                      ? translateError(props?.data[key]?.toString())
                      : key === "grz" || key === "message"
                        ? props?.data[key].toLowerCase()
                        : props?.data[key]
                }
                title={translateResKey(key)}
              />
            </Box>
          );
        } else return "";
      })}
    </React.Fragment>
  );
}
