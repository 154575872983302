import { InputAdornment, TextField } from "@mui/material";
import { GetData } from "contexts/DataContext";
import React from "react";

import { Box } from "@mui/system";

export default function FieldBlock(props) {
  const { dataObj } = GetData();
  const elRef = React.useRef(null);

  return (
    <React.Fragment>
      <Box
        sx={{
          overflow: "auto",

          background: "transparent",
          boxShadow: "none",
          // margin: "0.4rem 1rem",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          id={props?.label}
          sx={{
            margin: "0.5rem 1rem",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <TextField
            InputProps={{
              style: {
                fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
              },
              endAdornment: props?.icon ? (
                <InputAdornment sx={{ marginRight: "-12px" }} position="end">
                  {props?.icon}
                </InputAdornment>
              ) : undefined,
            }}
            error={props.required ? !props.value : undefined}
            fullWidth={true}
            helperText={props.helperText ? props.helperText : undefined}
            inputProps={{
              maxLength: props?.length ? props?.length : undefined,
              style: { textTransform: "uppercase" },
            }}
            inputRef={elRef}
            label={props?.title}
            mb={2}
            mt={2}
            name={props?.label}
            onBlur={
              props?.onBlurFunc
                ? (e) => props?.onBlurFunc(e.target.value)
                : undefined
            }
            onChange={(e) => props.setValue(e.target.value.toUpperCase())}
            onKeyPress={
              props?.onBlurFunc
                ? (e) => {
                    if (e.key === "Enter") {
                      elRef.current.blur();
                      // elRef.current.blur();
                      // props?.onBlurFunc(e.target.value);
                    }
                  }
                : undefined
            }
            type={props?.type ? props?.type : "text"}
            value={props.value}
            variant="standard"
          />
        </Box>
        <Box sx={{ marginTop: "18px" }}>{props?.component}</Box>
      </Box>
    </React.Fragment>
  );
}
