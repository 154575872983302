import { FolderOutlined } from "@mui/icons-material";
import { Box, Chip, LinearProgress, Paper } from "@mui/material";
import CustomizedBreadcrumbs from "components/Breadcrumbs";
import { GetCategoriesList } from "components/gql/queris/GetCategoriesList";
import { GetCategoryElements } from "components/gql/queris/GetCategoryElements";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  getBreadcrumbs,
  getCategories,
  isIdentical,
  isObjectEmpty,
  recursiveSearch,
} from "data/functions";
import { FolderBlock } from "pages/services/FolderBlock";
import Services from "pages/services/Services";
import React, { useEffect, useState } from "react";

export const FolderPage = (props) => {
  const { dataObj, setDataObj } = GetData();
  const [item, setItem] = useState(dataObj?.catArray || {});
  const [br, setBr] = useState([]);
  const [visible, setVisible] = React.useState();
  const { cInfo } = GetCInfo();
  const [catRefetch, catVariables] = GetCategoriesList();
  const [elRefetch, elVariables] = GetCategoryElements();

  useEffect(() => {
    getCategories(
      catRefetch,
      elRefetch,
      item?.id || "",
      { dataObj, setDataObj },
      { cInfo },
    );

    setBr(getBreadcrumbs(dataObj?.catArray, item?.id || "", "id"));
    // eslint-disable-next-line
  }, [item]);

  useEffect(() => {
    let val = recursiveSearch(dataObj?.catArray, item?.id || "", "id");

    if (val && !isIdentical(val, item)) {
      setItem(val);
    } else if (isObjectEmpty(item) || item?.id === "") {
      setItem(dataObj?.catArray);
    }

    // eslint-disable-next-line
  }, [dataObj?.catArray]);

  return (
    <React.Fragment>
      <Paper sx={{ position: "sticky", top: 0, zIndex: 3 }}>
        <CustomizedBreadcrumbs array={br} func={setItem} />
      </Paper>

      <Box sx={{ height: "2px" }}>
        {((catVariables.loading && isObjectEmpty(item?.children)) ||
          (elVariables.loading && isObjectEmpty(item?.elements))) && (
          <LinearProgress sx={{ height: "2px" }} />
        )}
      </Box>

      <Box
        sx={{
          padding: "0.4rem",

          display: "flex",
          flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
          flexWrap: "wrap",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
        }}
      >
        {(!isObjectEmpty(item?.children) ? item?.children : []).map(
          (el, ind) => {
            return (
              <Box key={ind} onClick={() => setItem(el)}>
                <FolderBlock
                  chip={<Chip label="Папка" color="secondary" />}
                  iconElement={
                    <FolderOutlined
                      sx={{ width: "0.86em", height: "0.86em" }}
                    />
                  }
                  image={
                    ind < 2
                      ? "https://mira.bootlab.io/static/img/unsplash/unsplash-1.jpg"
                      : ""
                  }
                  title={el.name}
                />
              </Box>
            );
          },
        )}

        <Services
          checked={props.checked}
          handleChecked={props.handleChecked}
          parent={item}
        />
      </Box>
    </React.Fragment>
  );
};
