import { Box, Checkbox } from "@mui/material";
import { GetPrices } from "components/gql/queris/GetPrices";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { getElementsPrices, isIdentical, isObjectEmpty } from "data/functions";
import { FolderBlock } from "pages/services/FolderBlock";
import React, { useState } from "react";

export default function Services(props) {
  const { dataObj, setDataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { getPrice, inBasket, sectionItemAdd, sectionItemDelete, sections } =
    GetBasket();
  const [pricesQuery] = GetPrices();
  const [arrayOfService, setArrayOfService] = useState(
    props?.parent?.elements || [],
  );

  React.useEffect(() => {
    if (!isObjectEmpty(arrayOfService)) {
      getElementsPrices(
        pricesQuery,
        arrayOfService,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayOfService, dataObj?.usedCar]);

  React.useEffect(() => {
    if (!isIdentical(arrayOfService, props?.parent?.elements)) {
      setArrayOfService(props?.parent?.elements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.parent?.elements]);

  function handleChecked(value) {
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "0.4rem",
          display: "flex",
          flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
          flexWrap: "wrap",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
          alignItems: dataObj?.deviceWidth?.isMobile ? undefined : "flex-end",
        }}
      >
        {arrayOfService &&
          arrayOfService?.map((value, index) => {
            let carID = dataObj?.usedCar?.car?.id;
            let priceObj = getPrice(value?.id, carID);
            let price = priceObj?.price || 0;
            let salePrice = priceObj?.salePrice || 0;
            const labelId = `checkbox-list-label-${value}`;

            let check = inBasket(value, sections.services);
            return (
              <Box
                key={index}
                onClick={() => {
                  handleChecked(value);
                }}
              >
                <FolderBlock
                  description={value?.description}
                  iconElement={
                    <Checkbox
                      checked={check || false}
                      disableRipple
                      edge="start"
                      inputProps={{ "aria-labelledby": labelId }}
                      size="small"
                      sx={{ padding: "0.4rem" }}
                      tabIndex={-1}
                    />
                  }
                  image={
                    value.name.toLowerCase().includes("шиномонтаж")
                      ? "https://cloud5.5-systems.ru/5systems/шиномонтаж.jpg"
                      : value.name.toLowerCase().includes("колес")
                        ? "https://cloud5.5-systems.ru/5systems/колеса.jpg"
                        : value.name.toLowerCase().includes("диагностика")
                          ? "https://cloud5.5-systems.ru/5systems/диагностика.jpg"
                          : ""
                  }
                  price={
                    price ? (
                      price > salePrice ? (
                        <React.Fragment>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <strike>{price}</strike>₽
                            </div>
                            {salePrice + "₽"}
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>{salePrice + "₽"}</React.Fragment>
                      )
                    ) : (
                      ""
                    )
                  }
                  thereIsEl={true}
                  title={value.name}
                />
              </Box>
            );
          })}
      </Box>
    </React.Fragment>
  );
}
