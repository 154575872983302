import { Box, Typography } from "@mui/material";
import React from "react";
import { useTimer } from "react-timer-hook";

export function Timer(props) {
  // const [select, setSelect] = React.useState(expiryTimestamp || 10);
  // const [remVisible, setRemVisible] = React.useState(true);
  const [expiryTimestamp, setExpiryTimestamp] = React.useState(60);

  const {
    minutes,
    // hours,
    // days,
    // isRunning,
    // start,
    // pause,
    // resume,
    restart,
    seconds,
    totalSeconds,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      props.setRemainingTime(0);
    },
  });

  React.useEffect(() => {
    if (props.expiryTimestamp) {
      setExpiryTimestamp(props.expiryTimestamp);
      restart(props.expiryTimestamp);
      // else start();
    }
    // eslint-disable-next-line
  }, [props.expiryTimestamp]);

  React.useEffect(() => {
    if (totalSeconds > 0) props.setRemainingTime(totalSeconds);
    // eslint-disable-next-line
  }, [totalSeconds]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {(minutes > 0 || seconds > 0) && (
        <React.Fragment>
          {props?.variant === "code" && (
            <Typography sx={{ textAlign: "center" }} variant="body2">
              Повторная отправка возможна
              <br /> через {minutes ? minutes + " мин." : ""} {seconds} сек.
            </Typography>
          )}
          {props?.variant === "qr" && (
            <Typography sx={{ textAlign: "center" }} variant="body1">
              Обновится через {minutes || seconds ? minutes + 1 + " мин. " : ""}
            </Typography>
          )}
          {props?.variant === "url" && (
            <Typography sx={{ textAlign: "center" }} variant="body2">
              До окончания срока действия ссылки
              <br /> осталось {minutes ? minutes + " мин. " : ""}
              {seconds} сек.
            </Typography>
          )}
        </React.Fragment>
      )}
      {!totalSeconds && props?.variant === "url" && (
        <Typography color="darkred" variant="body2">
          Срок действия ссылки истек.
        </Typography>
      )}
    </Box>
  );
}
