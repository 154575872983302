import React, { useState } from "react";

import { Box, Button, Container, Link, Typography } from "@mui/material";

import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";
import { saveAuthData, showAuthScreen } from "data/mobapp_functions";

import { Timer } from "components/Timer";
import { ExtFlowInitQuery } from "components/gql/queris/GetExtFlowInit";
import { GetAuthData } from "contexts/TAuthContext";
import { formatPhone, isObjectEmpty } from "data/functions";
import { TokenDialog } from "pages/auth/TokenDialog";
import PhoneField from "pages/deal/fields/PhoneField";
import { StatefulPinInput } from "react-input-pin-code";
import { useNavigate } from "react-router-dom";

import { CompanyErrorPage } from "components/CompanyError";
import { GetCInfo } from "contexts/CompanyContext";
import { AuthMethodButtons } from "pages/auth/AuthMethodButtons";
import { TelegramBlock } from "pages/auth/TelegramBlock";
import { CodeBlock } from "./CodeBlock";

function Copyright(props) {
  return (
    <Typography
      align="center"
      color="text.secondary"
      variant="body2"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://5systems.ru/">
        5S AUTO
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme?.

export default function SignIn() {
  const { dataObj } = GetData();
  const { isLogined } = GetAuthData();
  const [active, setActive] = useState(false);
  const { cInfo } = GetCInfo();
  const [error, setError] = useState(false);
  const [method, setMethod] = useState("");
  const navigate = useNavigate();
  const { setAlert } = GetAlertData();
  const [flowlRefetch, flowVars] = ExtFlowInitQuery();

  // React.useEffect(() => {
  //   let company = cInfo?.UUID;
  //   // if (!company) {
  //   //   throw new Error("Компания не определена");
  //   // }
  //   console.log(cInfo);
  // }, [cInfo?.UUID]);

  // React.useEffect(() => {
  //   if (method === "telegram") {
  //     setActive(true);
  //   } else setActive(false);
  //   // setCodeCheck("00000");
  // }, [method]);

  React.useEffect(() => {
    if (isLogined === true) {
      saveAuthData(dataObj?.clientID);
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isLogined, dataObj?.clientID]);

  React.useEffect(() => {
    showAuthScreen();
  }, []);

  return (
    <Box>
      {error && (
        <CompanyErrorPage
          text={
            <>
              Для компании не заданы <br /> методы авторизации
            </>
          }
        />
      )}
      <TokenDialog />
      <AuthBodyContainer active={active} error={error} method={method}>
        <AuthMethodButtons
          flowVars={flowVars}
          method={method}
          setError={setError}
          setMethod={setMethod}
        />
        {(method === "sms" || method === "phone") && (
          <CodeBlock
            active={active}
            flowVars={flowVars}
            flowlRefetch={flowlRefetch}
            method={method}
            setActive={setActive}
            setMethod={setMethod}
          />
        )}
        {method === "telegram" && (
          <TelegramBlock
            flowVars={flowVars}
            flowlRefetch={flowlRefetch}
            method={method}
            setActive={setActive}
            setMethod={setMethod}
          />
        )}
        {method && (
          <AuthButtonBack
            method={method}
            setActive={setActive}
            setMethod={setMethod}
          />
        )}
      </AuthBodyContainer>
    </Box>
  );
}

function AuthButtonBack(props) {
  return (
    <React.Fragment>
      {props?.method && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <Button
            onClick={() => {
              props?.setMethod("");
              props?.setActive(false);
            }}
            variant="body2"
          >
            Назад
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}

function AuthBodyContainer(props) {
  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          // minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          padding: "1rem",
          background: "transparent",
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          {!props?.active && !props?.error && (
            <Typography
              component="h2"
              sx={{ paddingBottom: "1rem", textAlign: "center" }}
              variant="h4"
            >
              {props?.method
                ? props?.method === "sms"
                  ? "Вход по SMS"
                  : props?.method === "phone"
                    ? "Вход по звонку"
                    : "Вход через Telegram"
                : "Вход"}
            </Typography>
          )}
          <Box noValidate sx={{ mt: 1 }}>
            {props?.children}
          </Box>
        </Box>
      </Container>
      <Copyright />
    </React.Fragment>
  );
}
