import { useLazyQuery } from "@apollo/client";
import { GET_CAR_LIST } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, isIdentical, isObjectEmpty } from "data/functions";

export function CarListQuery() {
  const { token } = GetAuthData();
  const { dataObj, forceSetDataObj, setDataObj } = GetData();
  const [carRefetch] = useLazyQuery(GET_CAR_LIST, {
    fetchPolicy: "no-cache",
    skip: !token,
    // notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      onCompletedCarList(data, { dataObj, forceSetDataObj, setDataObj });
    },
    onError: (e) => {
      onCompletedCarList(undefined, { dataObj, forceSetDataObj, setDataObj });
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "CarList: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [carRefetch];
}

export function onCompletedCarList(
  data,
  { dataObj, forceSetDataObj, setDataObj },
) {
  let newArr = (!isObjectEmpty(data?.carsList) ? data?.carsList : []).sort(
    (a, b) => {
      let dateA = new Date(a?.lastVisitAt || 0);
      let dateB = new Date(b?.lastVisitAt || 0);
      if (dateA < dateB) return 1;
      else return -1;
    },
  );
  newArr = newArr.map((el, ind) => {
    el.regNum = el.regNum.toLowerCase();
    return el;
  });

  if (isObjectEmpty(newArr)) {
    setDataObj([], "carArr");
    setDataObj({}, "usedCar");
    setDataObj([], "carArrWithSold");
    setDataObj([], "carIds");
    setDataObj([], "carIdsWithSold");
    forceSetDataObj(0, "carsCount");
  } else if (!isIdentical(newArr, dataObj?.carArrWithSold)) {
    consoleBeauty("Получен список машин", "GraphQL", "darkgreen");
    let carListWithoutSold = [];
    let carIds = [];
    let carIdsWithSold = [];
    newArr.forEach((element) => {
      carIdsWithSold = [...carIdsWithSold, element?.car?.id];
      if (element.sold === false) {
        carIds = [...carIds, element?.car?.id];
        carListWithoutSold = [...carListWithoutSold, element];
      }
    });
    if (!isIdentical(dataObj?.carIds, carIds)) setDataObj(carIds, "carIds");
    if (!isIdentical(dataObj?.carIdsWithSold, carIdsWithSold))
      setDataObj(carIds, "carIdsWithSold");
    setDataObj(carListWithoutSold, "carArr");
    if (!dataObj.usedCar) {
      setDataObj(carListWithoutSold[0], "usedCar");
    } else {
      let find = carListWithoutSold.find((el) => {
        if (el?.car?.id === dataObj.usedCar?.car?.id) {
          return true;
        } else return false;
      });
      if (!isObjectEmpty(find)) setDataObj(find, "usedCar");
    }
    setDataObj(new Date(Date.now()), "lastDataUpdate");
    setDataObj(newArr, "carArrWithSold");
    forceSetDataObj(carListWithoutSold.length, "carsCount");
  }
}
