import { Box } from "@mui/material";
import { ReactComponent as Logo } from "data/images/logo.svg";
import React from "react";

import { AccountBox, ChatOutlined, ShoppingBasket } from "@mui/icons-material";
import { GetData } from "contexts/DataContext";
import { CreditBlock } from "pages/main/mobile/CreditBlock";
import { TopButtonBlock } from "pages/main/mobile/TopButtonBlock";

export function MobileTitle() {
  const { dataObj } = GetData();

  return (
    <Box
      sx={{
        padding: "12px",
        minHeight: "60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 2,
        boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, 
          rgba(0, 0, 0, 0.12) 0px 1px 3px 0px`,
      }}
    >
      <Logo
        style={{
          maxWidth: "45px",
          maxHeight: "45px",
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CreditBlock />

        <TopButtonBlock
          badgeContent={dataObj?.currentBasketCount}
          icon={<ShoppingBasket />}
          title="Корзина"
          to="basket"
        />

        {dataObj?.includeBeta && (
          <TopButtonBlock
            badgeContent={2}
            icon={<ChatOutlined />}
            title="Чат"
            to="chat"
          />
        )}

        <TopButtonBlock
          badgeContent={dataObj?.activeOrdersCount}
          icon={<AccountBox />}
          title="Профиль"
          to="profile"
        />
      </Box>
    </Box>
  );
}
