import { Box, Grid } from "@mui/material";
import { CarListBlock } from "components/CarListBlock";
import { Widjet } from "components/Widjet";
import { MiniDocumentSlider } from "components/sliders/MiniDocumentSlider";
import { GetData } from "contexts/DataContext";

import { ServiceSlider } from "components/sliders/ServiceSlider";
import React from "react";
import { MainFooter } from "../MainFooter";

export function DesktopMainPage() {
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item lg={12} xl={12} xs={12}>
          <CarListBlock />
          <Box sx={{ display: "flex" }}>
            {" "}
            <Widjet
              header="Записаться на ремонт"
              margin="0.5rem"
              navigateOnClick="/request"
              width="100%"
            />
            <Widjet
              body={dataObj?.creditBalance}
              footer="бонусов"
              header="Бонусные баллы"
              margin="0.5rem"
              navigateOnClick="/credits"
              width="100%"
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            {" "}
            <Widjet
              header="Услуги и цены"
              margin="0.5rem"
              navigateOnClick="/services"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
      <ServiceSlider />
      <MiniDocumentSlider />

      <MainFooter />
    </React.Fragment>
  );
}
