import React, { useState } from "react";

import { Box, List } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import { isIdentical } from "data/functions";
import { isObjectEmpty } from "data/functions";
import { Outlet, useNavigate } from "react-router-dom";

export default function PromoMain(props) {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Акции"} />
        <Box sx={{ overflow: "auto" }}>
          <PromoList />
          <Outlet />
        </Box>
      </Box>
    </React.Fragment>
  );
}

export function PromoList(props) {
  const { dataObj } = GetData();
  const [promoArr, setPromoArr] = useState(dataObj?.promotions || []);
  React.useEffect(() => {
    if (!isIdentical(promoArr, dataObj?.promotions))
      setPromoArr(dataObj?.promotions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.promotions]);

  const navigate = useNavigate();
  return (
    <List
      sx={{
        padding: "0 0.4rem",
        display: dataObj?.deviceWidth?.isMobile ? "block" : "flex",
        flexWrap: dataObj?.deviceWidth?.isMobile ? undefined : "wrap",
        gap: dataObj?.deviceWidth?.isMobile ? undefined : "10px",
      }}
    >
      {!isObjectEmpty(promoArr) &&
        promoArr.map((el, index) => {
          if (props?.promoLimit ? index < props?.promoLimit : true) {
            return (
              <Box
                key={index}
                onClick={(e) =>
                  navigate(`/promotions/${el?.id}`, {
                    state: { id: el?.id },
                  })
                }
                sx={{
                  margin: "0.4rem 0",

                  height: dataObj?.deviceWidth?.isMobile
                    ? "calc(33vw - 0.66rem)"
                    : dataObj?.deviceWidth?.isPad
                      ? "calc(25vw - 0.66rem)"
                      : "10vw",
                  width: dataObj?.deviceWidth?.isMobile
                    ? "100%"
                    : dataObj?.deviceWidth?.isPad
                      ? "48%"
                      : "32%",
                  backgroundImage: !isObjectEmpty(el?.preview)
                    ? `url(${el?.preview})`
                    : !isObjectEmpty(el?.main)
                      ? `url(${el?.main})`
                      : "",
                  backgroundSize: "cover",
                  backgroundPosition: "top center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            );
          } else return "";
        })}
    </List>
  );
}
