import { Settings, ShoppingBasket } from "@mui/icons-material/";
import {
  Badge,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CarBlock } from "components/CarBlock";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ServiceTitle(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <React.Fragment>
      <Grid container spacing={0} sx={{ alignItems: "center" }}>
        <Grid item xs={8}>
          <PageTitle alignStart={true} title="Услуги" />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            disableRipple
            onClick={() => {
              navigate("/basket");
            }}
            size="small"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: "200px",

              //
            }}
          >
            <Tooltip title={"Перейти в корзину"}>
              <Badge badgeContent={dataObj.currentBasketCount} color="primary">
                <ShoppingBasket
                  sx={{
                    animation:
                      (dataObj.currentBasketCount || 0) > 0
                        ? "wobble 3s infinite"
                        : "none",
                    borderRadius: "20px",
                  }}
                />
              </Badge>
            </Tooltip>
            {!dataObj?.deviceWidth?.isMobile && (
              <Typography sx={{ fontSize: "12px" }} variant="body1">
                {"Корзина"}
              </Typography>
            )}
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            disableRipple
            onClick={() => {
              props?.setHide((prev) => !prev);
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: "200px",
            }}
          >
            <Tooltip
              title={
                props?.hide
                  ? "Показать выбор автомобиля"
                  : "Скрыть выбор автомобиля"
              }
            >
              <Settings />
            </Tooltip>

            {!dataObj?.deviceWidth?.isMobile && (
              <Typography sx={{ fontSize: "12px" }} variant="body1">
                {props?.hide
                  ? "Показать выбор автомобиля"
                  : "Скрыть выбор автомобиля"}
              </Typography>
            )}
          </IconButton>
        </Grid>
      </Grid>
      {!props?.hide && <CarBlock />}
    </React.Fragment>
  );
}
