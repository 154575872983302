import { CameraAlt, PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";

import { GetGrzDecode } from "components/gql/queris/GetGrzDecode";
import { GibddUploadUrlQuery } from "components/gql/queris/GibddUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { translateError } from "data/functions";
import { DragFileElement } from "data/styleGlobals";
import React from "react";
import { uploadFile } from "tools/requests";
import { ResponseParamsBlock } from "./ResponseButtonWithDialog";

export default function DecodeGrz(props) {
  const [grzDecodeQuery] = GetGrzDecode();
  const [queryUpload] = GibddUploadUrlQuery();
  const { dataObj } = GetData();
  const inputRef = React.useRef(null);
  const { setAlert } = GetAlertData();
  const { cInfo } = GetCInfo();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [grzRes, setGrzRes] = React.useState(false);

  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };

  async function changeImageArray(array) {
    if (array && array[0]) {
      showLoading();
      let { data } = await queryUpload({
        variables: {
          filename: array[0].name,
        },
      });
      if (data?.gibdd_uploadUrl?.fileID) {
        let success = await uploadFile(data?.gibdd_uploadUrl, array[0]);
        if (!success) {
          setAlert("Не удалось загрузить файл для расшифровки", "warning");
        } else {
          let grzData = await grzDecodeQuery({
            variables: {
              fileId: data?.gibdd_uploadUrl?.fileID,
            },
          });
          if (grzData?.data && grzData?.data?.gibdd_grzDecode?.grz) {
            setGrzRes(grzData?.data?.gibdd_grzDecode);
            props?.setCarAddData((e) => {
              return {
                ...e,
                plate: grzData?.data?.gibdd_grzDecode?.grz,
              };
            });
            props?.checkPlate(grzData?.data?.gibdd_grzDecode?.grz);
            setOpen(false);
          }
          if (grzData?.error && translateError(grzData?.error?.message)) {
            setAlert(translateError(grzData?.error?.message), "warning");
          }
        }
      }
      hideLoading();
    }
  }

  function showLoading() {
    setLoading(true);
  }

  function hideLoading() {
    setLoading(false);
  }

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    changeImageArray(e.dataTransfer.files);
    e.dataTransfer.value = null;
  };

  const find = (cInfo?.channels ?? []).find((el) => {
    if (el?.name === "Gibdd_Sts") return true;
    else return false;
    // return true;
  });

  return (
    <React.Fragment>
      {find && (
        <React.Fragment>
          <input
            accept="image/*"
            alt="Гос. номер"
            id={`input-file-upload-grz`}
            multiple={false}
            onChange={handleChange}
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
          />

          <IconButton
            aria-hidden={false}
            disableRipple
            // onClick={(e) => {
            //   inputRef.current.click();
            // }}
            onClick={(e) => {
              setOpen(true);
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PhotoCamera aria-hidden={false} />
          </IconButton>
          <Dialog
            maxWidth="sm"
            onClose={(e) => {
              setOpen(false);
            }}
            open={open}
          >
            <DialogTitle sx={{ fontSize: "1rem" }}>
              Загрузите фото гос/номера автомобиля
            </DialogTitle>
            <DialogContent>
              <Box
                id="form-file-upload"
                onDragEnter={(e) => handleDrag(e)}
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <Input
                  id={`input-file-upload-grz`}
                  multiple={false}
                  onChange={(e) => handleChange(e)}
                  ref={inputRef}
                  sx={{ display: "none" }}
                  type="image"
                /> */}
                {!dataObj?.deviceWidth?.isMobile &&
                  !dataObj?.deviceWidth?.isPad && (
                    <label
                      className={
                        dragActive
                          ? "label-file-upload drag-active "
                          : "label-file-upload"
                      }
                      htmlFor={`input-file-upload-grz`}
                      id={`label-file-upload-grz`}
                    >
                      <CameraAlt sx={{ width: "36px", height: "36px" }} />
                    </label>
                  )}
                {dragActive && (
                  <DragFileElement
                    id={`drag-file-element ${props.variant}`}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  />
                )}
              </Box>
              {loading && <LinearProgress />}
              <ResponseParamsBlock data={grzRes} name={"grzRes"} />
            </DialogContent>
            <DialogActions>
              <Button
                aria-hidden={false}
                onClick={(e) => {
                  inputRef.current.click();
                }}
                sx={{ margin: "0.4rem" }}
                variant="outlined"
              >
                Загрузить
              </Button>

              <Button
                aria-hidden={false}
                onClick={(e) => {
                  setOpen(false);
                }}
                sx={{ margin: "0.4rem" }}
                variant="outlined"
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
