import { CalendarMonth } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import { findInArray, isIdentical } from "data/functions";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function PromotionProfile(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const { dataObj } = GetData();
  const params = useParams();
  const ID = location.state?.id || params.id;

  const [item, setItem] = useState(
    findInArray(dataObj?.promotions, ID, "id") || {},
  );

  React.useEffect(() => {
    let val = findInArray(dataObj?.promotions, ID, "id");
    if (!isIdentical(item, val)) {
      setItem(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.promotions]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Акция"} />
        <Box sx={{ overflow: "auto" }}>
          <Box
            sx={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              padding: "8px 0",
              backgroundImage: `url(${item?.main})`,
              backgroundSize: "cover",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
            }}
          />

          <Paper
            sx={{
              position: "relative",
              marginTop: "-2rem",
              padding: "0.4rem",
              // height: "calc(100% - 320px)",
              boxShadow: "none",
              borderRadius: " 20px 20px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "0.4rem",
                marginLeft: "0.4rem",
                alignItems: "center",
              }}
            >
              <CalendarMonth />
              {item?.time}
            </Box>
            <Typography align="center" variant="h5">
              {item?.title}
            </Typography>
            <Box sx={{ fontSize: "1rem", padding: "12px 12px" }}>
              {item?.body}
            </Box>
            <Box sx={{ fontSize: "1rem", textAlign: "center" }}>
              <Button onClick={() => navigate("/request")} variant="outlined">
                Записаться
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}
