import { GetMarkQuery } from "components/gql/queris/GetMark";
import { GetMarksQuery } from "components/gql/queris/GetMarks";
import { GetModelsQuery } from "components/gql/queris/GetModels";
import { GetData } from "contexts/DataContext";
import { isIdentical, isObjectEmpty } from "data/functions";
import React, { useState } from "react";
import SelectBlock from "../SelectBlock";
import { getModels } from "./FieldModels";

export default function FieldMark(props) {
  const [markImage, setMarkImage] = useState(props?.carAddData?.markName);
  const { dataObj } = GetData();
  const marksQuery = GetMarksQuery();
  const [modelsQuery] = GetModelsQuery();
  const [markQuery] = GetMarkQuery();

  React.useEffect(() => {
    if (
      !isObjectEmpty(dataObj?.markList) &&
      !isIdentical(dataObj?.markList, props?.carAddData?.markList)
    ) {
      props?.setCarAddData((e) => {
        return { ...e, markList: dataObj?.markList };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.markList]);

  React.useEffect(() => {
    getMarkImage(props?.carAddData?.mark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.carAddData?.mark]);

  async function getModelsArray(e) {
    if (!e) {
      props?.setCarAddData((e) => {
        return { ...e, model: null, modelList: [] };
      });
    }
    if (
      !isObjectEmpty(props?.carAddData?.modelList) &&
      props?.carAddData?.modelList[0].markId === e
    )
      return;
    let res = await getModels(dataObj, modelsQuery, e);
    if (!isIdentical(res ?? [], props?.carAddData?.modelList)) {
      props?.setCarAddData((e) => {
        return { ...e, model: null, modelList: res ?? [] };
      });
    }
  }

  async function getMarkImage(mark) {
    if (!mark?.id) {
      setMarkImage("");
      return;
    }
    if (mark?.presignURL?.URL) {
      setMarkImage(mark?.presignURL?.URL);
      return;
    }
    let res = await getImage(markQuery, mark?.id);
    if (!isObjectEmpty(res) && res?.presignURL?.URL) {
      setMarkImage(res?.presignURL?.URL);
      return;
    }
    setMarkImage("");
  }

  function changeMark(value) {
    if (!isIdentical(value, props?.carAddData?.mark)) {
      getModelsArray(value?.id);
      getMarkImage(value);
      props?.setCarAddData((e) => {
        return { ...e, mark: value };
      });
    }
  }

  return (
    <SelectBlock
      array={props?.carAddData?.markList}
      helperText={
        props?.helperText?.markText ? props?.helperText?.markText : undefined
      }
      label="marka"
      loading={marksQuery?.loading}
      onClear={() => {
        if (props?.carAddData?.markList !== (dataObj?.markList ?? [])) {
          props?.setCarAddData((e) => {
            return { ...e, markList: dataObj?.markList ?? [] };
          });
        }
      }}
      required={props?.required ? props?.required : undefined}
      // setInputValue={changeMarkName}
      setSecondValue={getModelsArray}
      setValue={changeMark}
      startIconUrl={markImage}
      title="Марка"
      value={props?.carAddData?.mark}
    />
  );
}

export async function getImage(markQuery, markID) {
  if (!markID) {
    return {};
  }
  let { data, error } = await markQuery({
    variables: { markId: markID },
  });
  if (data?.exch_getMark) {
    return data?.exch_getMark;
  }
  if (error) {
    return {};
  }
  return {};
}
