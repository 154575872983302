import styled from "@emotion/styled";
import { Add } from "@mui/icons-material";
import { IconButton, TableCell as MuiTableCell, TableRow } from "@mui/material";
import { TableItemRow } from "components/registration/TableItemRow";
import React, { useEffect, useState } from "react";

const TableCell = styled(MuiTableCell)`
  padding: 4px;
`;

export function SelectedBlock(props) {
  const [open, setOpen] = useState(false);
  const [array, setArray] = useState([]);

  useEffect(() => {
    setArray(props?.array);
  }, [props?.array]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          align="left"
          colSpan={3}
          sx={{
            // fontWeight: 700,
            fontSize: "0.875rem",
          }}
        >
          {props.label}
        </TableCell>
      </TableRow>

      {array?.map((element, index) => (
        <TableItemRow
          element={element}
          key={index}
          section={props?.section}
          size={props?.size}
        />
      ))}
    </React.Fragment>
  );
}
