import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  concat,
  from,
} from "@apollo/client";
import { apolloConfig } from "config";
import React from "react";

import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { GetAuthData } from "contexts/TAuthContext";
import { getLocalToken } from "data/functions";

function ApolloContext({ children }) {
  const removeTypenameLink = removeTypenameFromVariables();
  const { token } = GetAuthData();

  const httpLink = new HttpLink({
    uri: apolloConfig.uri,
  });

  let lStToken = getLocalToken();
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token ? token : lStToken ? lStToken : ""}`,
      },
    }));
    return forward(operation);
  });

  const link = from([removeTypenameLink, concat(authLink, httpLink)]);

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: link,
    name: "web",
    version: "1.0",
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export { ApolloContext };
