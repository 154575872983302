import { Map } from "@mui/icons-material";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import React from "react";

export default function RegionField(props) {
  const { dataObj } = GetData();
  const { cInfo, setCInfo } = GetCInfo();
  const [region, setRegion] = React.useState(() => {
    if (props?.department?.region) return props?.department?.region;
    if (cInfo?.preferedRegion?.name) return cInfo?.preferedRegion?.name;
    return "";
  });

  React.useEffect(() => {
    let reg = "";
    if (props?.department?.region) reg = props?.department?.region;
    if (cInfo?.preferedRegion?.name) reg = cInfo?.preferedRegion?.name;
    if (region !== reg) {
      setRegion(reg);
    }
  }, [props?.department, cInfo?.preferedRegion]);

  return (
    <React.Fragment>
      {!isObjectEmpty(cInfo?.regions) && cInfo?.regions?.length > 1 && (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Map />
              </InputAdornment>
            ),
            style: {
              fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
            },
          }}
          error={!region}
          // helperText={!props.department?.name ? "Выберите подразделение" : ""}
          fullWidth
          label="Регион"
          mb={2}
          mt={2}
          name="region"
          // defaultValue=""
          select
          sx={{
            minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
            margin: "0.3rem 0",
          }}
          type="text"
          value={region || ""}
          variant="standard"
        >
          {(cInfo?.regions ?? [])?.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={(e) => {
                  setCInfo(item, "preferedRegion");
                  setRegion(item?.name);
                  props?.setDepartment(null);
                }}
                value={item?.name}
              >
                {item?.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    </React.Fragment>
  );
}
