import { Box, Paper, Typography } from "@mui/material";
import { GetData } from "contexts/DataContext";
import React from "react";
import { useNavigate } from "react-router-dom";

export function Widjet(props) {
  // const [usedCar, setUsedCar] = useState({});
  // const [carArr, setCarArr] = useState([]);
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <Paper
      elevation={dataObj?.deviceWidth?.isMobile ? 0 : 1}
      onClick={() => {
        if (props.navigateOnClick) navigate(props.navigateOnClick);
      }}
      sx={{
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        width: props?.width ? props?.width : "25%",
        justifyContent: "center",
        margin: props?.margin ? props?.margin : "2rem",
        borderRadius: "0.5rem",
        cursor: props.navigateOnClick ? "pointer" : "unset",
        "&:hover": {
          backgroundColor: "rgb(0 0 0 / 5%)",
        },
        minHeight: "70px",
      }}
    >
      <Box
        sx={{
          padding: "0.5rem 1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: dataObj?.deviceWidth?.isMobile ? "row" : "column",
        }}
      >
        {props.header && (
          <Typography align="center" sx={{ lineHeight: "1.0" }} variant="h6">
            {props.header}
          </Typography>
        )}

        {props.body && props.footer && (
          <Typography align="center" sx={{ lineHeight: "1.0" }} variant="h6">
            {props.body}
            {props.footer && (
              <Typography
                align="center"
                sx={{
                  padding: 0,
                  lineHeight: "1.0",
                  fontSize: "0.8rem",
                }}
                variant="body1"
              >
                {props.footer}
              </Typography>
            )}
          </Typography>
        )}
      </Box>
    </Paper>
  );
}
