export const promotions = [
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "1",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_3.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Мы проверим по 15 пунктам следующие узлы и агрегаты на Вашем автомобиле:
            1. Наружные осветительные приборы
            2. Проверка состояния шин
            3. Проверка состояния щеток стеклоочистителя
            4. Проверка состояния АКБ, U нагр,
            5. Проверка состояния АКБ, U без нагр,
            6. Уровень и состояние охлаждающей жидкости
            7. Уровень и состояние тормозной жидкости
            8. Уровень и состояние жидкости ГУРа
            9. Осмотр ДВС на предмет утечек и отпотеваний
            10. Состояние приводных ремней
            11. Проверка состояния опор и подушек ДВС
            12. Стойки стабилизатора
            13. Втулки стабилизатора
            14. Подшипники ступиц
            15. Шаровые опоры`,
    id: "2",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_3.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_2.png",
    time: "11.04.24-01.01.30",
    title: "ТЕХОБСЛУЖИВАНИЕ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "3",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "4",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "5",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "6",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
];

export const emptyItem = {
  createdForRelease: { id: 0, name: "" },
  description: "",
  favorite: "",
  id: 0,
  name: "",
  result: "",
  solvedInRelease: { id: 0, name: "" },
  state: "NEW",
  // createdAt: getCurrentDate(),
  ticketnumber: "",
};
