import { useLazyQuery } from "@apollo/client";
import { ORDER_READ } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, findInArray } from "data/functions";
import { isObjectEmpty } from "data/functions";

export function OrderReadQuery() {
  const { dataObj, pushDataArr } = GetData();
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(ORDER_READ, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onCompleted: (data) => {
      onCompletedOrderRead(data, dataObj, pushDataArr);
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}

export function onCompletedOrderRead(data, dataObj, pushDataArr) {
  if (isObjectEmpty(data?.orderRead?.orderID)) return;

  let order = findInArray(dataObj?.orders, data?.orderRead?.orderID, "orderID");
  if (!order) {
    consoleBeauty(
      "Получена информация по " + data?.orderRead?.orderID,
      "GraphQL",
      "darkgreen",
    );
    pushDataArr(data?.orderRead, "orders", []);
  }
}
