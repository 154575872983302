import { DecodeVinMutation } from "components/gql/mutations/DecodeVin";
import { GetMarkQuery } from "components/gql/queris/GetMark";
import { GetModelQuery } from "components/gql/queris/GetModel";
import { GetModelsQuery } from "components/gql/queris/GetModels";
import { SearchMark } from "components/gql/queris/SearchMark";
import { SearchModel } from "components/gql/queris/SearchModel";
import { GetData } from "contexts/DataContext";
import { decodeVin, isIdentical, isObjectEmpty } from "data/functions";
import React from "react";
import DecodeSts from "../DecodeSts";
import FieldBlock from "./FieldBlock";
import { getModels } from "./FieldModels";

export default function FieldVin(props) {
  const [vin, setVin] = React.useState(props?.carAddData?.vin || "");
  const [vinQuery] = DecodeVinMutation();
  const { dataObj } = GetData();
  const [modelsQuery] = GetModelsQuery();
  const [markQuery] = GetMarkQuery();
  const [getModelQuery] = GetModelQuery();

  React.useEffect(() => {
    if (!isIdentical(vin, props?.carAddData?.vin)) {
      setVin(props?.carAddData?.vin);
    }
  }, [props?.carAddData]);

  React.useEffect(() => {
    props?.setCarAddData((e) => {
      return { ...e, vin: vin };
    });
  }, [vin]);

  async function decodeVin(vin) {
    let res = await checkVin(vin, vinQuery, dataObj);
    if (!isObjectEmpty(res)) {
      props?.setHelperText((e) => {
        let ret = { ...e };
        ret = { ...ret, markText: res?.markText ?? "" };
        ret = { ...ret, modelText: res?.modelText ?? "" };
        return ret;
      });
      let mark = {};
      let model = {};
      let models = {};

      if (!isObjectEmpty(res?.models) && !isObjectEmpty(res?.models[0])) {
        let modelRes = await getModelQuery({
          variables: {
            markId: res?.models[0]?.markId,
            modelId: res?.models[0]?.id,
          },
        });
        if (modelRes?.data && modelRes?.data.exch_getModel)
          model = modelRes?.data.exch_getModel;
        let markRes = await markQuery({
          variables: { markId: res?.models[0]?.markId },
        });
        if (markRes?.data && markRes?.data.exch_getMark)
          mark = markRes?.data.exch_getMark;

        models = await getModels(dataObj, modelsQuery, mark[0]);
      }
      props?.setCarAddData((e) => {
        let ret = {
          ...e,
          mark: mark ? mark : null,
          markList: !isObjectEmpty(res?.marks)
            ? res?.marks
            : dataObj?.markList ?? [],
        };

        if (!isObjectEmpty(model)) {
          ret = {
            ...ret,
            model: model,
            modelList: models,
          };
        } else {
          ret = {
            ...ret,
            model: null,
          };
        }
        return ret;
      });
    }
  }
  return (
    <FieldBlock
      // component={
      //   <ResponseButtonWithDialog
      //     data2={vinData}
      //     dialogTitle2="Расшифровка VIN"
      //     data={fileData}
      //     dialogTitle="Свидетельство о регистрации ТС"
      //   />
      // }
      helperText={
        props?.helperText?.vinText ? props?.helperText?.vinText : undefined
      }
      icon={
        <DecodeSts
          carAddData={props?.carAddData}
          setCarAddData={props?.setCarAddData}
          setHelperText={props?.setHelperText}
        />
      }
      label="vin"
      length={24}
      onBlurFunc={decodeVin}
      required={props?.required}
      setValue={setVin}
      title="VIN"
      value={vin}
    />
  );
}

export async function checkVin(v, vinQuery, dataObj) {
  if (v.length < 17) return;
  let [marks, models, error] = await decodeVin(v, vinQuery);
  if (error) {
    return {};
  }
  let vinRes = {};

  let markHelperText = createMarkModelHelperText(marks);
  vinRes = {
    ...vinRes,
    markText: markHelperText,
    marks: marks,
  };

  let modelHelperText = createMarkModelHelperText(models);
  vinRes = {
    ...vinRes,
    modelText: modelHelperText,
  };

  vinRes = {
    ...vinRes,
    models: models,
  };

  return vinRes;
}

function createMarkModelHelperText(arr) {
  if (isObjectEmpty(arr)) return "";
  let ind = 0;
  let helper = [];
  arr.forEach((element) => {
    ind = ind + 1;
    if (ind <= 5) helper = [...helper, element.name];
  });

  let res = helper.join("; ") || "";
  if (arr.length > 5) res = res + " и др.";
  return res;
}
