import React from "react";

import { DriveEta } from "@mui/icons-material/";
import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import {
  findInArray,
  getCarImage,
  getCarName,
  isObjectEmpty,
} from "data/functions";
import { CarAddBlock } from "pages/cars/list/Main";
import { useNavigate } from "react-router-dom";

export default function CarListItem(props) {
  const { dataObj, setDataObj } = GetData();

  const navigate = useNavigate();
  return (
    <React.Fragment>
      {!isObjectEmpty(props?.el) && (
        <ListItem
          key={props?.index}
          onClick={() => {
            let path = "";
            if (props?.el?.car?.vin) {
              path = props?.el?.car?.vin;
            } else {
              path = "car";
            }
            if (props?.navigate)
              navigate(`/cars/${path}`, {
                state: { carID: props?.el?.car?.id },
              });
            let inArray = findInArray(dataObj?.carArr, props?.el);
            if (!isObjectEmpty(props?.el) && inArray) {
              setDataObj(props?.el, "usedCar");
            }
          }}
          sx={{
            margin: "2px 0px",
            paddingRight: "24px",
            boxShadow:
              "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <ListItemAvatar
            id={"avatar_" + props?.index}
            sx={{
              width: "40px",
              minWidth: "40px",
              // height: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {props?.el?.car?.marka?.imageUrl ? (
              <img
                alt={props?.el?.car?.vin}
                id={"image_" + props?.index}
                src={props?.el?.car?.marka?.imageUrl}
                style={{ overflow: "hidden", width: "40px" }}
              />
            ) : (
              <Avatar
                sx={{
                  background: "transparent",
                  boxShadow: "inset 0px 0px 2px #00000026",
                  width: "40px",
                }}
              >
                <DriveEta color="dimblue" />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            sx={{ padding: "0 0.4rem" }}
            // primary={`${props?.el.car.marka.name} ${props?.el.car.model.name}`}
            // secondary={`${props?.el.regNum} ${props?.el.sold ? "Продан" : ""}`}
          >
            <Typography
              variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
            >
              {getCarName(props?.el)}
              {props?.el?.sold ? "Продан" : ""}
            </Typography>
            {props?.el?.regNum && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  minHeight: "20px",
                  width: "90px",
                  flexWrap: "no-wrap",
                  border: "1px solid #b8b8b8",
                  borderRadius: "4px",
                }}
              >
                <Box
                  sx={{
                    borderRight: "1px solid #b8b8b8",
                    width: "60px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant={
                      dataObj?.deviceWidth?.isMobile ? "caption" : "body2"
                    }
                  >
                    {props?.el?.regNum.slice(0, 6)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "30px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Typography
                    variant={
                      dataObj?.deviceWidth?.isMobile ? "caption" : "body2"
                    }
                  >
                    {props?.el?.regNum.slice(6, props?.el?.regNum.length)}
                  </Typography>
                </Box>
              </Box>
            )}
          </ListItemText>
          <ListItemAvatar
            id={"avatar_" + props?.index}
            sx={{ width: "110px", display: "flex", justifyContent: "center" }}
          >
            {props?.el?.car?.model?.imageUrl ? (
              <img
                alt={props?.el?.car?.vin}
                id={"image_" + props?.index}
                src={props?.el?.car?.model?.imageUrl}
                style={{ overflow: "hidden" }}
              />
            ) : (
              <Avatar
                sx={{
                  background: "transparent",
                  boxShadow: "inset 0px 0px 2px #00000026",
                }}
              >
                <DriveEta color="dimblue" />
              </Avatar>
            )}
          </ListItemAvatar>
          <Divider />
        </ListItem>
      )}
      {isObjectEmpty(dataObj?.carArr) && isObjectEmpty(props?.el) && (
        <Box sx={{ position: "relative" }}>
          <CarAddBlock />
        </Box>
      )}
    </React.Fragment>
  );
}
