import styled from "@emotion/styled";
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Chip, Collapse, ListItemButton, ListItemText } from "@mui/material";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const Item = styled(ListItemButton)`
  svg {
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
`;

const Badge = styled(Chip)`
  height: 20px;
  position: absolute;
  right: 16px;
  top: 10px;
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    padding-left: ${(props) => props.theme?.spacing(4)};
    padding-right: ${(props) => props.theme?.spacing(4)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)``;

const ExpandMoreIcon = styled(ExpandMore)``;

const SidebarNavListItem = (props) => {
  const {
    badge,
    children,
    depth = 0,
    href,
    icon: Icon,
    open: openProp = false,
    title,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        activeclassname="active"
        component={CustomRouterLink}
        depth={depth}
        to={href}
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
