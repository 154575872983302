import {
  Box,
  Button,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  getCarImage,
  getCarName,
  getImageUrlByName,
  isIdentical,
  isObjectEmpty,
  recursiveSearchCat,
  searchInCatArray,
  translateResKey,
} from "data/functions";
import { ParamRow } from "pages/orders/info/Main";
import React, { useState } from "react";

import { HelpIconWithTest } from "data/styleGlobals";
import { useLocation, useParams } from "react-router-dom";
import ServiceTitle from "../ServiceTitle";

export default function ServiceProfile(props) {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const {
    basket,
    getPrice,
    inBasket,
    isEmptyContext,
    prices,
    sectionItemAdd,
    sectionItemDelete,
    sections,
  } = GetBasket();

  let location = useLocation();
  const params = useParams();
  const serviceID = location.state?.serviceID || params.id;
  const [item, setItem] = useState({});
  const [price, setPrice] = useState(0);
  const [sale, setSale] = useState(0);
  const [priceType, setPriceType] = useState("");
  const [added, setAdded] = useState(false);
  React.useEffect(() => {
    let val = (dataObj?.serviceList ?? []).find((el) => el?.id === serviceID);
    if (!isIdentical(val, item)) {
      setItem(val);
    }
    // eslint-disable-next-line
  }, [dataObj?.catArray]);

  React.useEffect(() => {
    if (!isObjectEmpty(item)) {
      setAdded(inBasket(item, sections.services));
      setItemPrice(item);
    }
    // eslint-disable-next-line
  }, [basket, item, prices]);

  function handleChecked(value) {
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }

  function setItemPrice(element) {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let fSalePrice = 0;
    let fPrice = 0;
    let type = "";
    let priceObj = getPrice(element?.id, carID);
    if (priceObj) {
      fSalePrice = fSalePrice + priceObj?.salePrice;
      fPrice = fPrice + priceObj?.price;
      type = priceObj?.priceType;
    }
    setPriceType(type);
    setPrice(fSalePrice);
    setSale(fPrice - fSalePrice);
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <ServiceTitle />{" "}
        {item?.name && (
          <Typography sx={{ padding: "1rem" }} variant="h4">
            {item?.name}
          </Typography>
        )}
        <Box
          sx={{
            // margin: "0rem 1rem 1rem 1rem",
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Box sx={{ width: "100%", overflow: "auto", position: "relative" }}>
            {getImageUrlByName(item?.name) && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    padding: dataObj?.deviceWidth?.isMobile ? "1rem" : "24px",
                    maxWidth: dataObj?.deviceWidth?.isMobile ? "100%" : "500px",
                    maxHeight: "350px",
                    textAlign: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    alt="service"
                    src={getImageUrlByName(item?.name)}
                    style={{ maxWidth: "100%", maxHeight: "500px" }}
                  />
                </Box>
              </Box>
            )}
            <Box
              sx={{ padding: dataObj?.deviceWidth?.isMobile ? "1rem" : "24px" }}
            >
              {/* {Object.keys(item).map((key, ind) => (
                <Box key={ind}>
                  {translateResKey(key) && (
                    <ParamRow
                      subTitle={item[key]}
                      title={translateResKey(key)}
                    />
                  )}
                </Box>
              ))} */}
              <Typography variant="body1">{item?.description}</Typography>
            </Box>
          </Box>{" "}
          <Paper
            sx={{
              width: dataObj?.deviceWidth?.isMobile ? "100%" : "33%",
              padding: dataObj?.deviceWidth?.isMobile ? "12px" : "24px",
              display: "flex",
              justifyContent: dataObj?.deviceWidth?.isMobile
                ? "space-around"
                : "flex-start",
              flexDirection: dataObj?.deviceWidth?.isMobile
                ? "row-reverse"
                : "column",
            }}
          >
            <Button
              color={added ? "error" : undefined}
              // disabled={isEmptyContext("registration")}
              fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
              onClick={() => handleChecked(item)}
              size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
              sx={{ textTransform: "none" }}
              variant="contained"
            >
              {added ? "Убрать из корзины" : "Добавить в корзину"}
            </Button>

            {/* {price > 0 && ( */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0.4rem 12px 0px 12px",
                  gap: "20px",
                }}
              >
                {/* {!dataObj?.deviceWidth?.isMobile && ( */}
                {priceType === "empty_price" ? (
                  <Typography sx={{ textAlign: "right" }} variant="h6">
                    Цена по запросу
                  </Typography>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ textAlign: "right" }} variant="h6">
                      Цена:
                    </Typography>
                    {/* )} */}

                    <Typography
                      sx={{ textAlign: "right", textWrap: "nowrap" }}
                      variant="h6"
                    >
                      {priceType === "min_price"
                        ? "от " + price + " ₽"
                        : price + " ₽"}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>

              {sale > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0px 12px 0.4rem 12px",
                    gap: "20px",
                  }}
                >
                  {/* {!dataObj?.deviceWidth?.isMobile && ( */}
                  <Typography sx={{ textAlign: "right" }} variant="body1">
                    Скидка:
                  </Typography>
                  {/* )} */}
                  <Typography
                    color="error"
                    sx={{ textAlign: "right", textWrap: "nowrap" }}
                    variant="body1"
                  >
                    - {sale} ₽
                  </Typography>
                </Box>
              )}
              <Typography
                sx={{
                  textAlign: "right",
                  textWrap: "wrap",
                  padding: "0px 12px",
                }}
                variant="body2"
              >
                {!isObjectEmpty(dataObj?.usedCar)
                  ? "для " + getCarName(dataObj?.usedCar)
                  : ""}
              </Typography>
            </Box>
            {/* )} */}
          </Paper>
        </Box>
        {/* <ServicesButtons /> */}
      </Box>
    </React.Fragment>
  );
}
