import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const PromotionSlider = (props) => {
  const [array, setArray] = useState([]);
  const { dataObj } = GetData();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isObjectEmpty(dataObj?.promotions)) {
      setArray(dataObj?.promotions);
    } else {
      setArray([]);
    }
  }, [dataObj?.promotions]);
  return (
    <React.Fragment>
      {/* {!isObjectEmpty(dataObj?.activePromotion) && ( */}
      <Box sx={{ margin: "0.4rem 0", width: "97%" }}>
        <Swiper
          autoplay={
            !dataObj?.deviceWidth?.isMobile
              ? {
                  delay: 15000,
                  disableOnInteraction: false,
                }
              : false
          }
          // centeredSlides={true}
          injectStyles={[":host .swiper { align-items: center !important; }"]}
          modules={[Navigation, Autoplay, Pagination, Mousewheel]}
          mousewheel={{
            releaseOnEdges: true,
          }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={
            dataObj?.deviceWidth?.isMobile
              ? 1
              : dataObj?.deviceWidth?.isPad
                ? 3
                : 5
          }
          spaceBetween={12}
          style={{
            padding: "0.4rem 36px",
            width: "100%",
          }}
        >
          {(array ? array : []).map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                onClick={(e) =>
                  navigate(`/promotions/${item?.id}`, {
                    state: { id: item?.id },
                  })
                }
                style={{
                  display: "flex",
                  flexDirection: "column",
                  /* align-content: center; */
                  alignItems: "center",
                  justifyContent: "center",
                  // minHeight: "70px",
                  // margin: "12px",
                  // minWidth: "80%",
                  height: "auto",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                    maxHeight: "200px",
                  }}
                >
                  <img
                    alt={item?.title}
                    src={item?.preview}
                    style={{
                      // height: "calc(33vw - 0.66rem)",
                      maxHeight: "inherit",
                      maxWidth: "100%",
                    }}
                  />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      {/* )} */}
    </React.Fragment>
  );
};
