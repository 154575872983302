import { Box, Button, Paper } from "@mui/material";
import { GetBasket } from "contexts/BasketContext";
import { UseOnScreen, isObjectEmpty } from "data/functions";
import { BoxSubmit } from "data/styleGlobals";
import { RejectDialog } from "pages/recs/RejectDialog";
import React from "react";
import { useNavigate } from "react-router-dom";

//not currently in use
export default function RecButtons(props) {
  return (
    <React.Fragment>
      <UseOnScreen>
        <Buttons />
      </UseOnScreen>
    </React.Fragment>
  );
}

function Buttons() {
  const { basket, sections } = GetBasket();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  let empty = isObjectEmpty(basket[sections?.recommendations] || []);
  if (empty) return;
  return (
    <React.Fragment>
      <Paper
        sx={{
          // background: "transparent",
          padding: "4px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Button
          color="error"
          onClick={() => setOpen(true)}
          sx={{ textDecoration: "underline" }}
        >
          Отказаться
        </Button>

        <Button
          color={"tgButton"}
          onClick={() => navigate("/basket")}
          variant="outlined"
        >
          В корзину
        </Button>
      </Paper>
    </React.Fragment>
  );
}
