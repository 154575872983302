import { useLazyQuery } from "@apollo/client";
import { CATEGORIES_LIST } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function GetCategoriesList() {
  const [refetch, variables] = useLazyQuery(CATEGORIES_LIST, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}
