import React, { useState } from "react";

import { Key } from "@mui/icons-material/";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { StorageDialog } from "components/StorageDialog";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { createTokenData, setLocalStorageItem } from "data/functions";
import { useLongPress } from "pages/profile/info/ProfileMenu";

export const TokenDialog = (props) => {
  const { dataObj } = GetData();
  const [value, setValue] = useState("");
  const { setTokenData } = GetAuthData();
  // const [selfDataQuery, selfVars] = SelfDataQuery();
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const longPressProps = useLongPress({
    // onClick: (ev) => console.log("on click", ev.button, ev.shiftKey),
    onLongPress: () => setOpenDialog(true),
  });

  return (
    <React.Fragment>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        {...longPressProps}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "2rem",
          width: "2rem",
        }}
      />
      <Dialog
        // PaperProps={{
        //   style: {
        //     width: "90vw",
        //     maxWidth: "90vw",
        //     minHeight: "90vh",
        //   },
        // }}
        onClose={() => setOpen(false)}
        open={open}
        sx={{ zIndex: 1201 }}
      >
        <DialogTitle>Изменение данных токена</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
          Для отказа от выданных рекомендаций введите пожалуйста причину отказа.
        </DialogContentText> */}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              ),
            }}
            // error={!name}
            // helperText={!name ? "Заполните это поле" : ""}
            fullWidth
            label="JSON RAW!! TokenData"
            mb={2}
            mt={2}
            multiline={false}
            name="tokenData"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            sx={{
              minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
              margin: "0.3rem 0",
            }}
            type="text"
            value={value}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue("");
              setOpen(false);
            }}
            variant="outlined"
          >
            Отмена
          </Button>
          <LoadingButton
            // loading={selfVars?.loading}
            onClick={() => {
              try {
                let obj = JSON.parse(value);
                setLocalStorageItem("tokenDataOriginal", obj);
                let tokenData = createTokenData(
                  obj?.access_token,
                  obj?.expires_in,
                  obj?.refresh_token,
                  obj?.refresh_expires_in,
                );
                console.log(tokenData);
                setTokenData(tokenData);
              } catch (error) {}
            }}
            variant="outlined"
          >
            Сохранить
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <StorageDialog setOpen={setOpenDialog} status={openDialog} />
    </React.Fragment>
  );
};
