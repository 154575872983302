import { AttachFile } from "@mui/icons-material";
import { Badge, IconButton, InputAdornment, TextField } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { FilesDialog } from "pages/deal/FilesDialog";
import React from "react";

export default function CommentField(props) {
  const { dataObj } = GetData();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TextField
        InputProps={{
          style: {
            fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
          },
          endAdornment: (
            <InputAdornment position="start" sx={{ margin: "16px 0px" }}>
              <IconButton
                // sx={{ svg: { width: "16px", height: "16px" } }}
                onClick={(e) => setOpen(true)}
              >
                <Badge badgeContent={props?.filesArray.length}>
                  <AttachFile />
                </Badge>
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        label="Комментарий"
        maxRows={dataObj?.deviceWidth?.isMobile ? 4 : undefined}
        mb={2}
        mt={2}
        multiline
        name="comment"
        onChange={(e) => props.setComment(e.target.value)}
        rows={dataObj?.deviceWidth?.isMobile ? undefined : 4}
        sx={{ margin: "0.875rem 0" }}
        type="text"
        value={props.comment}
        // variant={dataObj?.deviceWidth?.isMobile ? "standard" : "outlined"}
        variant="standard"
      />
      <FilesDialog
        filesArray={props?.filesArray}
        setFilesArray={props?.setFilesArray}
        setOpen={setOpen}
        status={open}
      />
    </>
  );
}
