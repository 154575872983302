import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { AddCarMutation } from "components/gql/mutations/AddCar";
import { CarListQuery } from "components/gql/queris/GetCarList";
import { findInArray } from "data/functions";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ButtonsBlock(props) {
  const [addQuery] = AddCarMutation();
  const [carRefetch] = CarListQuery();
  const navigate = useNavigate();

  async function addCar() {
    if (!props?.vin || !props?.marka || !props?.model) {
      props?.setError(() => true);
      // setAlert("Заполните обязательные поля", "warning");
      return;
    }
    let marka = findInArray(props?.markList, props?.marka?.id, "id");
    let model = findInArray(props?.modelList, props?.model?.id, "id");
    let variables = {
      input: {
        marka: {
          id: marka.id,
          name: marka.name,
        },
        model: {
          id: model.id,
          name: model.name,
        },
        vin: props?.vin,
      },
    };
    if (props?.plate) {
      variables = {
        ...variables,
        input: {
          ...variables.input,
          regNumber: props?.plate,
        },
      };
    }
    if (props?.mileage) {
      variables = {
        ...variables,
        input: {
          ...variables.input,
          lastMileage: parseInt(props?.mileage),
        },
      };
    }
    if (props?.year) {
      let yearNumber = props?.year?.getFullYear();
      if (yearNumber)
        variables = {
          ...variables,
          input: {
            ...variables.input,
            year: yearNumber,
          },
        };
    }
    // console.log(variables);
    let { data } = await addQuery({ variables: variables });
    if (data && data?.carAdd?.code === 200) {
      let { data, error } = await carRefetch();
      if (data || error) navigate("/cars");
    }
  }
  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ margin: "0.4rem" }}
      >
        <Button
          // onClick={props?.reset}
          onClick={() => navigate(-1)}
          variant="outlined"
        >
          Отмена
        </Button>
        <Button onClick={addCar} variant="outlined">
          Сохранить
        </Button>
      </Stack>
    </React.Fragment>
  );
}
