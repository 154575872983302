import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

export function TopButtonBlock(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <React.Fragment>
      <Tooltip title={props?.title}>
        <Box sx={{ overflow: "visible !important" }}>
          <IconButton
            disableRipple
            onClick={(e) => {
              navigate(props?.to);
            }}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <Badge
              badgeContent={props?.badgeContent || 0}
              color="primary"
              sx={{
                "& svg": {
                  fill: theme?.palette?.mode === "light" ? "#000" : undefined,
                  width: "1.2em",
                  height: "1.2em",
                },
              }}
            >
              {props?.icon}
            </Badge>
          </IconButton>
        </Box>
      </Tooltip>
    </React.Fragment>
  );
}
