import { KeyboardArrowRight } from "@mui/icons-material/";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function HeaderRow(props) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        margin: "0.4rem",
        padding: 0,
        background: "transparent",
        boxShadow: props?.boxShadow ? props?.boxShadow : undefined,
      }}
    >
      <CardContent sx={{ padding: "0.36rem 0!important" }}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            onClick={(e) => {
              if (props.navigateOnClick) navigate(props.navigateOnClick);
            }}
            sx={{
              cursor: props.navigateOnClick ? "pointer" : "unset",
            }}
          >
            <Typography
              sx={{
                padding: "0rem 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
              variant="h6"
            >
              {props.text}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {(props.leftHeaderText || props.leftBodyText) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0 8px",
                    }}
                  >
                    {/* <Typography
                      align="center"
                      sx={{ lineHeight: "1.0" }}
                      variant="h6"
                    > */}
                    {props.leftHeaderText}
                    {props.leftBodyText && (
                      <Typography
                        align="center"
                        sx={{
                          padding: 0,
                          lineHeight: "1.0",
                          fontSize: "0.8rem",
                        }}
                        variant="body1"
                      >
                        {props.leftBodyText}
                      </Typography>
                    )}
                    {/* </Typography> */}
                  </Box>
                )}
                {props?.navigateOnClick && <KeyboardArrowRight />}
              </Box>
            </Typography>
          </Box>
          {props?.buttonText && (
            <Button
              color={"inherit"}
              onClick={(e) => {
                if (props.navigateOnButton) navigate(props.navigateOnButton);
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: props?.navigateOnClick ? "40px" : "0px",
                height: "30px",
                textTransform: "none",
              }}
            >
              {props?.buttonText}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
