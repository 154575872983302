import React from "react";

import { Box } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { DepartmentBlock } from "pages/departments/list/DepartmentBlock";
import { useNavigate } from "react-router-dom";

export default function Departments() {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Контакты" />
        <Box sx={{ overflow: "auto" }}>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
              flexWrap: "wrap",
              margin: 0,
            }}
          >
            {!isObjectEmpty(cInfo?.departments) &&
              cInfo?.departments.map((el, ind) => {
                return (
                  <Box
                    key={ind}
                    onClick={(e) =>
                      navigate(`/departments/${el?.ID}`, {
                        state: { orderID: el?.ID },
                      })
                    }
                    sx={{ width: "100%", padding: "0.6rem" }}
                  >
                    <DepartmentBlock
                      description={""}
                      locationLatitude={el?.locationLatitude}
                      locationLongitude={el?.locationLongitude}
                      phone={el?.phone}
                      reviews={[
                        { name: "Иван", text: "Хороший сервис!", count: "4" },
                        {
                          name: "Ваня",
                          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                          count: "2",
                        },
                      ]}
                      subheader={el?.adress}
                      title={el?.name}
                      workTime={el?.workTime}
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
