import { useMutation } from "@apollo/client";
import { CAR_UPDATE } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";

import { GetAlertData } from "contexts/AlertContext";
import { assignedArray, consoleBeauty } from "data/functions";

export function CarUpdateMutation() {
  const { setAlert } = GetAlertData();
  const { dataObj, setDataObj } = GetData();
  const [carUpdate, carUpdateVars] = useMutation(CAR_UPDATE, {
    onCompleted: (data) => {
      onCompletedCarUpdate(data, { dataObj, setDataObj }, { setAlert });
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );
      console.groupCollapsed("carUpdate Error");

      consoleBeauty(e.message, "GraphQL", "red");
      console.groupEnd("carUpdate Error");
    },
  });
  return [carUpdate, carUpdateVars];
}

export function onCompletedCarUpdate(
  data,
  { dataObj, setDataObj },
  { setAlert },
) {
  let carIds = [];
  let carIdsWithSold = [];
  let carListWithSold = assignedArray(dataObj.carArrWithSold).map(
    (item, index) => {
      carIdsWithSold = [...carIdsWithSold, item.car.id];
      if (item.car.id === data.carUpdate.car.id) {
        return data.carUpdate;
      }
      return item;
    },
  );

  let carList = assignedArray(carListWithSold).filter((item, index) => {
    return item?.sold !== true;
  });
  carList.forEach((element) => {
    carIds = [...carIds, element?.car?.id];
  });

  setDataObj(carList, "carArr");
  setDataObj(carListWithSold, "carArrWithSold");
  setDataObj(carIds, "carIds");
  setDataObj(carIdsWithSold, "carIdsWithSold");
  consoleBeauty("Обновлен список машин", "GraphQL", "darkgreen");
  setAlert("Изменения внесены", "success");
}
