import { useMutation } from "@apollo/client";
import { USER_UPDATE } from "components/gql/gql_queries";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty } from "data/functions";

export function UserUpdateMutation() {
  const { setAlert } = GetAlertData();

  const [userUpdate, userUpdateVars] = useMutation(USER_UPDATE, {
    onCompleted: (data) => {
      if (data?.userUpdate?.success) {
        consoleBeauty(
          "Успешное изменение пользователя",
          "GraphQL",
          "darkgreen",
        );
        setAlert("Данные сохранены", "success");
      } else {
        consoleBeauty(
          "Не удалось сохранить новые данные пользователя",
          "GraphQL",
          "red",
        );
        setAlert("Не удалось сохранить новые данные", "error");
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
      setAlert("Не удалось сохранить новые данные", "error");
    },
  });

  return [userUpdate, userUpdateVars];
}
