import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { AddCarMutation } from "components/gql/mutations/AddCar";
import { CarListQuery } from "components/gql/queris/GetCarList";
import { isObjectEmpty } from "data/functions";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ButtonsBlock(props) {
  const [addQuery] = AddCarMutation();
  const [carRefetch] = CarListQuery();
  const navigate = useNavigate();
  const location = useLocation();
  async function addCar() {
    if (
      !props?.carAddData?.vin ||
      isObjectEmpty(props?.carAddData?.mark) ||
      isObjectEmpty(props?.carAddData?.model)
    ) {
      props?.setError(() => true);
      // setAlert("Заполните обязательные поля", "warning");
      return;
    }
    let marka = props?.carAddData?.mark;
    let model = props?.carAddData?.model;

    let variables = {
      input: {
        marka: {
          id: marka.id,
          name: marka.name,
        },
        model: {
          id: model.id,
          name: model.name,
        },
        vin: props?.carAddData?.vin,
      },
    };
    if (props?.carAddData?.plate) {
      variables = {
        ...variables,
        input: {
          ...variables.input,
          regNumber: props?.carAddData?.plate,
        },
      };
    }
    if (props?.carAddData?.mileage) {
      variables = {
        ...variables,
        input: {
          ...variables.input,
          lastMileage: parseInt(props?.carAddData?.mileage),
        },
      };
    }
    if (props?.carAddData?.year) {
      let yearNumber = props?.carAddData?.year?.getFullYear();
      if (yearNumber)
        variables = {
          ...variables,
          input: {
            ...variables.input,
            year: yearNumber,
          },
        };
    }
    // console.log(variables);
    let { data } = await addQuery({ variables: variables });
    if (data && data?.carAdd?.code === 200) {
      let { data, error } = await carRefetch();
      if (data || error) {
        if (location.state?.fromMain) navigate("/");
        else navigate("/cars");
      }
    }
  }
  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ margin: "0.4rem" }}
      >
        <Button
          // onClick={props?.reset}
          onClick={() => navigate(-1)}
          variant="outlined"
        >
          Отмена
        </Button>
        <Button onClick={addCar} variant="outlined">
          Сохранить
        </Button>
      </Stack>
    </React.Fragment>
  );
}
