import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

export default function FieldYear(props) {
  return (
    <Box sx={{ margin: "1rem 1rem" }}>
      <DatePicker
        disableFuture
        label={"Год выпуска"}
        onChange={(e) => props?.setYear(e)}
        openTo="year"
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "standard",
            helperText: props.helperText ? props.helperText : undefined,
          },
        }}
        value={props?.year}
        views={["year"]}
      />
    </Box>
  );
}
