import styled from "@emotion/styled";
import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";

import {
  Box,
  Card,
  Divider as MuiDivider,
  Fab,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: calc(
    100vh -
      ${(props) =>
        useMediaQuery(props.theme.breakpoints.up("md")) ? "90px" : "60px"}
  );
  background: transparent;
`;

const ChatMain = styled(Grid)`
  height: 100%;
`;

const ChatMessages = styled.div`
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme?.custom?.telegramColors?.text_color}
    transparent;
  height: calc(100% - 78px);
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
  padding: 0.4rem;
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: #dddddd1f;
  border-radius: 12px;
  padding: ${(props) => props.theme.spacing(6)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 25%);
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: 600;
`;

const ChatInput = styled(Grid)`
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: ${(props) => props.theme.spacing(5)};
`;

function ChatMessageComponent({
  gender,
  message,
  name,
  position = "left",
  time,
}) {
  const yourElementRef = React.useRef(null);
  const { dataObj } = GetData();
  React.useEffect(() => {
    if (yourElementRef.current) {
      yourElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <ChatMessage position={position} ref={yourElementRef}>
      <ChatMessageInner>
        <ChatMessageBubble highlighted={position === "right"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: position === "left" ? "row" : "row-reverse",
              flexWrap: "nowrap",
              justifyContent: "flex-start",
            }}
          >
            <ChatMessageBubbleName
              variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
            >
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
          >
            {message}
          </Typography>
        </ChatMessageBubble>
        <ChatMessageTime
          variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
        >
          {time}
        </ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function ChatWindow() {
  const { dataObj } = GetData();
  return (
    <ChatContainer component={Card} container>
      <ChatMain item lg={12} md={12} xs={12}>
        <ChatMessages>
          <ChatMessageComponent
            gender="female"
            message="Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo."
            name="Оператор"
            position="left"
            time="20 минут назад"
          />
          <ChatMessageComponent
            gender={dataObj?.clientInfoArray?.sex}
            message="Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
            name="Я"
            position="right"
            time="12 минут назад"
          />
          <ChatMessageComponent
            gender="female"
            message="Cum ea graeci tractatos. 😄"
            name="Оператор"
            position="left"
            time="8 минут назад"
          />
          <ChatMessageComponent
            gender={dataObj?.clientInfoArray?.sex}
            message="Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci. 👍"
            name="Я"
            position="right"
            time="5 минут назад"
          />
          <ChatMessageComponent
            gender="female"
            message="Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
            name="Оператор"
            position="left"
            time="3 минуты назад"
          />
        </ChatMessages>
        <Divider />
        <ChatInput container>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              placeholder="Написать сообщение..."
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Box ml={2}>
              <Fab aria-label="add" color="primary" size="medium">
                <SendIcon />
              </Fab>
            </Box>
          </Grid>
        </ChatInput>
      </ChatMain>
    </ChatContainer>
  );
}

function Chat() {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Чат" />
        <Box sx={{ overflow: "auto" }}>
          <ChatWindow />
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Chat;
