import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import QRBlock, { QRDialog } from "components/QRBlock";
import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";
import { dateToString, parseRFC3339 } from "data/functions";
import { isObjectEmpty } from "data/functions";
import AccrueInfo from "pages/credits/AccrueInfo";
import HistoryInfo from "pages/credits/HistoryInfo";

function BonusTotal() {
  const { dataObj } = GetData();
  return (
    <Box
      sx={{
        padding: "16px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">Мои БОНУСЫ:</Typography>{" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ lineHeight: "0.8" }} variant="h5">
          {dataObj?.creditBalance || 0}
        </Typography>
        <Typography variant="caption">бонусов</Typography>
      </Box>
    </Box>
  );
}

function BonusServs(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <Typography sx={{ padding: "16px 16px 4px 16px" }} variant="h5">
        Бонусные услуги
      </Typography>
      <Typography></Typography>
      {dataObj?.presentAccrue.map((el, ind) => (
        <Box
          key={ind}
          sx={{
            padding: "4px 24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            {el.name}
          </Typography>
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            {el?.lifePeriod?.expireDate !== "0001-01-01 00:00:00 +0000 UTC"
              ? dateToString(parseRFC3339(el?.lifePeriod?.expireDate), true)
              : ""}
          </Typography>
        </Box>
      ))}
    </React.Fragment>
  );
}

function BonusProgram() {
  return (
    <Box
      sx={{
        padding: "36px 16px 0px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">Статус: Стандартный</Typography>
    </Box>
  );
}

export default function Credits() {
  const { dataObj } = GetData();
  const { setAlert } = GetAlertData();
  const [accrueArr, setAccrueArr] = React.useState([]);
  const [historyArr, setHistoryArr] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Бонусы" />
        <Box sx={{ overflow: "auto" }}>
          <BonusTotal />
          <AccrueInfo setParentArray={setAccrueArr} />
          <Box
            onClick={(e) => setOpen(true)}
            sx={{
              maxHeight: "80px",
              margin: "0.4rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              position: "relative",
              padding: "8px",
              height: "80px",
              overflow: "hidden",
              cursor: "pointer",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
            }}
          >
            <Typography
              sx={{ textAlign: "left", fontWeight: "700" }}
              variant="body2"
            >
              Покажите QR-код, чтобы подтвердить списание бонусов или получить
              заказ
            </Typography>
            <Box
              sx={{
                display: "flex",
                minWidth: "80px",
                width: "80px",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <QRBlock />
            </Box>
          </Box>
          <BonusProgram />
          <HistoryInfo setParentArray={setHistoryArr} />
          <Box sx={{ padding: "0.4rem" }}>
            <Button
              fullWidth
              onClick={() => {
                setAlert("Функционал не реализован.", "info");
              }}
              sx={{ margin: "2em 0 " }}
              variant="outlined"
            >
              Подарить бонусы
            </Button>
          </Box>

          {!isObjectEmpty(dataObj?.presentAccrue) && <BonusServs />}
          {isObjectEmpty(historyArr) &&
            isObjectEmpty(dataObj?.presentAccrue) &&
            isObjectEmpty(accrueArr) && <>Нет данных</>}
        </Box>
      </Box>
      <QRDialog open={open} setOpen={setOpen} />
    </React.Fragment>
  );
}
