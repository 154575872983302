import axios from "axios";

export async function uploadFile(presignUrl, file) {
  const myHeaders = new Headers();
  myHeaders.append("X-Amz-Tagging", presignUrl?.xAmxTagging);

  var data = new FormData();
  data.append("file", file);
  const requestOptions = {
    body: file,
    headers: myHeaders,
    method: "PUT",
  };

  return await fetch(presignUrl?.url, requestOptions)
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      // console.log(result);
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}
