import { ChevronLeft } from "@mui/icons-material/";
import { Box, IconButton, Typography } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const PageTitle = (props) => {
  const navigate = useNavigate();
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "relative",
          // minHeight: "50px",
          margin: dataObj?.deviceWidth?.isMobile ? "12px 0" : undefined,
          display: props.display ? props.display : undefined,
        }}
      >
        <IconButton
          color={"dimdarkgreen"}
          disableRipple
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            width: dataObj?.deviceWidth?.isMobile ? "60px" : "40px",
            padding: 0,
          }}
          variant="outlined"
        >
          <ChevronLeft sx={{ opacity: 1, width: "1.2em", height: "1.2em" }} />
        </IconButton>

        <Box
          sx={{
            minHeight: "inherit",
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            // flexWrap: "wrap",
            alignItems:
              dataObj?.deviceWidth?.isMobile && !props?.alignStart
                ? "center"
                : "flex-start",
            alignContent: "center",
            gridGap: !dataObj?.deviceWidth?.isMobile ? "12px" : undefined,
            justifyContent: dataObj?.deviceWidth?.isMobile
              ? "center"
              : "flex-start",
            padding:
              dataObj?.deviceWidth?.isMobile && props?.alignStart
                ? "0.4rem 3rem"
                : dataObj?.deviceWidth?.isMobile
                  ? "0"
                  : "1rem 3rem",
          }}
        >
          <Typography
            sx={{
              textAlign: dataObj?.deviceWidth?.isMobile ? "center" : "start",
            }}
            variant={dataObj?.deviceWidth?.isMobile ? "h5" : "h4"}
          >
            {!isObjectEmpty(props.title) ? props.title : ""}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant={dataObj?.deviceWidth?.isMobile ? "h5" : "h4"}
          >
            {!isObjectEmpty(props.title2) ? props.title2 : ""}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
