import { gql } from "@apollo/client";

export const FIND_CLIENT = gql`
  query FindClient($phone: String, $companyUuid: String) {
    findClient(phone: $phone, companyUUID: $companyUuid) {
      id
      name
    }
  }
`;

export const GET_CAR_LIST = gql`
  query CarsList($userId: String) {
    carsList(userID: $userId, includeSold: true) {
      sold
      regNum
      lastVisitAt
      lastMileage
      car {
        id
        color
        marka {
          id
          name
          imageUrl
        }
        model {
          id
          name
          imageUrl
        }
        vin
        year
      }
    }
  }
`;

export const CAR_RECOMMENDATIONS_LIST = gql`
  query RecommendationsList($carId: String!, $userId: String!) {
    recommendationsList(carID: $carId, userID: $userId) {
      id
      text
      urgency
      createdAt
    }
  }
`;

export const GET_SELF_DATA = gql`
  query GetSelfData($token: String) {
    auth_getSelfData(token: $token) {
      clientID
      companyUUID
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  query UploadUrlQuery($filename: String!) {
    gibdd_uploadUrl(filename: $filename) {
      xAmxTagging
      url
      method
      fileID
    }
  }
`;

export const GET_MODEL = gql`
  query Exch_getModel($markId: String!, $modelId: String!) {
    exch_getModel(markID: $markId, modelID: $modelId) {
      id
      markId
      name
      presignURL {
        FileID
        URL
        Method
        XAmxTagging
      }
    }
  }
`;

export const GET_SELF_INFO = gql`
  query UserRead($companyUuid: String, $userId: String) {
    userRead(companyUUID: $companyUuid, userID: $userId) {
      id
      birthday
      email
      phone
      sex
      name {
        fullName
        lastName
        middleName
        firstName
      }
    }
  }
`;

export const REJECT_RECOMMENDATIONS = gql`
  mutation RecommendationReject($input: Rejection!) {
    recommendationReject(input: $input) {
      code
      success
      message
      result
    }
  }
`;

export const GET_DEPARTMENT_LIST = gql`
  query CompanyDepartments($companyUuid: String!) {
    companyDepartments(companyUUID: $companyUuid) {
      departments {
        ID
        name
        workTime
        adress
        locationLatitude
        locationLongitude
        phone
        region
      }
      regions {
        name
      }
    }
  }
`;

export const GET_COMPANY_CHANNELS = gql`
  query Acc_getCompanyChannels($companyUuid: String!) {
    acc_getCompanyChannels(companyUUID: $companyUuid) {
      name
      active
    }
  }
`;

export const CATEGORIES_LIST = gql`
  query CategoriesList($categoryId: String!, $companyUuid: String!) {
    categoriesList(categoryID: $categoryId, companyUUID: $companyUuid) {
      id
      name
      hasChildren
    }
  }
`;

export const SERVICES_LIST = gql`
  query ServicesList($companyUuid: String!, $categoryId: String) {
    servicesList(companyUUID: $companyUuid, categoryID: $categoryId) {
      name
      id
      description
    }
  }
`;

export const SERVICE_PRICE = gql`
  query ServicePrices(
    $companyUuid: String!
    $markaId: String
    $modelId: String
    $serviceId: [String!]
  ) {
    servicePrices(
      companyUUID: $companyUuid
      markaID: $markaId
      modelID: $modelId
      serviceID: $serviceId
    ) {
      price
      priceType
      salePrice
      service {
        id
        name
        description
      }
    }
  }
`;

export const ORDERS_LIST = gql`
  query OrdersList {
    ordersList {
      departmentID
      description
      docDate
      docNumber
      docSum {
        operations
        parts
        total
      }
      masterName
      mileage
      operations {
        count
        discount
        name
        sum
      }
      orderID
      parts {
        count
        discount
        name
        sum
      }
      status
      type
      planDate
      carID
      payPaid
      payWaiting
      payDebt
      payStatus
    }
  }
`;

export const ORDER_READ = gql`
  query OrderRead($orderId: String!) {
    orderRead(orderID: $orderId) {
      carID
      departmentID
      description
      docDate
      docNumber
      docSum {
        operations
        parts
        total
      }
      masterName
      mileage
      operations {
        count
        discount
        name
        sum
      }
      orderID
      parts {
        count
        discount
        name
        sum
      }
      status
      type
      planDate
      payPaid
      payWaiting
      payDebt
      payStatus
    }
  }
`;

export const CAR_UPDATE = gql`
  mutation CarUpdate($input: CarUpdateInput!) {
    carUpdate(input: $input) {
      car {
        id
        color
        marka {
          id
          name
          imageUrl
        }
        model {
          id
          name
        }
        vin
        year
      }
      lastMileage
      lastVisitAt
      regNum
      sold
    }
  }
`;

export const REJECT_REASONS = gql`
  query RejectReasons {
    rejectReasons {
      id
      name
    }
  }
`;

export const GET_CREDITS = gql`
  query CreditBalance($userId: String) {
    creditBalance(userID: $userId) {
      activeCreditsTotal
      credits {
        activeCredit
        inactiveCredit
        lifePeriod {
          expireDate
          activateDate
        }
      }
      inactiveCreditsTotal
      presents {
        name
        lifePeriod {
          expireDate
          activateDate
        }
        id
      }
    }
  }
`;

export const ADD_NEW_DEAL = gql`
  mutation DealAdd($input: DealAdd!) {
    dealAdd(input: $input) {
      code
      success
      message
      result {
        dealDate
        dealID
        dealNumber
      }
    }
  }
`;

export const CREDIT_HISTORY = gql`
  query CreditHistory($userId: String, $companyUuid: String) {
    creditHistory(userID: $userId, companyUUID: $companyUuid) {
      activeCredits
      date
    }
  }
`;

export const USER_UPDATE = gql`
  mutation Mutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      success
      message
      code
      user {
        id
        name {
          firstName
          middleName
          lastName
          fullName
        }
        birthday
        email
        phone
        sex
      }
    }
  }
`;

export const FLOW_INIT = gql`
  query GetExtFlowInit(
    $companyUuid: String!
    $channel: ChannelType!
    $phone: String
  ) {
    auth_getExtFlowInit(
      companyUUID: $companyUuid
      channel: $channel
      phone: $phone
    ) {
      expiresIn
      flowID
      telegramURL
    }
  }
`;

export const EXT_LOGIN = gql`
  mutation ExtLogIn($input: ExtLogInInput!) {
    auth_extLogIn(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const EXT_REFRESH_TOKEN = gql`
  mutation Auth_extRefreshToken($input: ExtRefreshTokenInput!) {
    auth_extRefreshToken(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const GET_MARKS = gql`
  query getMarks {
    exch_getMarks(size: 9999, page: 1) {
      marks {
        id
        name
        shortName
      }
    }
  }
`;

export const GET_MODELS = gql`
  query getModels($markId: String!) {
    exch_getModels(markID: $markId, size: 9999, page: 1) {
      models {
        id
        name
        yearFrom
        yearTo
      }
    }
  }
`;

export const DECODE_VIN = gql`
  mutation Exch_decodeVin($vin: String!) {
    exch_decodeVin(vin: $vin) {
      code
      success
      message
      data {
        manufacturers {
          id
          name
          shortName
        }
        models {
          id
          name
          yearFrom
          yearTo
        }
      }
    }
  }
`;

export const DECODE_PLATE = gql`
  query CarReport($grz: String!, $companyUuid: String) {
    gibdd_carReport(GRZ: $grz, CompanyUUID: $companyUuid) {
      vin
      sts
      grz
      brandOriginal
      brandNormalized
      year
      engineFuelType
      enginePowerHp
      enginePowerKw
    }
  }
`;

export const ADD_CAR = gql`
  mutation CarAdd($input: CarAddInput!) {
    carAdd(input: $input) {
      code
      success
      message
    }
  }
`;

export const SEARCH_DNS = gql`
  mutation Acc_searchDnsHostLink($host: String!) {
    acc_searchDnsHostLink(host: $host) {
      code
      success
      message
      data {
        companyUUID
        cName
        subDomain
      }
    }
  }
`;

export const FILE_UPLOAD = gql`
  mutation Gibdd_fileUpload($input: FileUploadInput!) {
    gibdd_fileUpload(input: $input) {
      code
      message
      result {
        fileId
        size
        contentType
      }
      success
    }
  }
`;

export const GRZ_DECODE = gql`
  query Gibdd_grzDecode($fileId: String!) {
    gibdd_grzDecode(fileID: $fileId) {
      grz
      grzs
      logUuid
    }
  }
`;

export const STS_DECODE = gql`
  query Gibdd_stsDecode($fileId: String!, $companyUuid: String) {
    gibdd_stsDecode(fileID: $fileId, CompanyUUID: $companyUuid) {
      brand
      chassis
      color
      grz
      logUuid
      model
      owner_fio
      stsNumber
      trailer
      view
      vin
      year
    }
  }
`;
