import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import { emphasize, styled } from "@mui/material/styles";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import * as React from "react";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    "&:active": {
      backgroundColor: emphasize(backgroundColor, 0.12),
      boxShadow: theme.shadows[1],
    },
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    backgroundColor,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    height: theme.spacing(8),
    padding: "9px 4px",
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function CustomizedBreadcrumbs(props) {
  const { dataObj } = GetData();
  return (
    <div onClick={handleClick} role="presentation">
      <Breadcrumbs
        aria-label="breadcrumb"
        maxItems={3}
        separator=""
        sx={{
          padding: "0 0.4rem",
          "& .MuiBreadcrumbs-separator": { margin: "0 2px" },
        }}
      >
        <StyledBreadcrumb
          component="a"
          href="#"
          icon={
            <HomeIcon
              fontSize="small"
              sx={{ width: "0.86em", height: "0.86em" }}
            />
          }
          label="Услуги"
          onClick={() => props?.func(undefined)}
        />
        {(!isObjectEmpty(props?.array) ? [...props?.array] : []).map(
          (el, ind) => (
            <StyledBreadcrumb
              component="a"
              // href="#"
              key={ind}
              label={el?.name}
              onClick={() => props?.func(el)}
            />
          ),
        )}
      </Breadcrumbs>
    </div>
  );
}
