import { Box, LinearProgress } from "@mui/material";
import { CarBlock } from "components/CarBlock";
import { PageTitle } from "components/PageTitle";
import { CarRecsQuery } from "components/gql/queris/GetCarRecs";
import { GetData } from "contexts/DataContext";
import RecList from "pages/recs/RecList";
import React from "react";
import RecButtons from "./RecButtons";
import RecsTitle from "./RecsTitle";

export default function Recs(props) {
  const { dataObj } = GetData();
  const [recVars] = CarRecsQuery();

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <RecsTitle />
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ height: "2px" }}>
            {recVars?.loading && <LinearProgress sx={{ height: "2px" }} />}
          </Box>
          <RecList />
          {/* <RecButtons /> */}
        </Box>
      </Box>
    </React.Fragment>
  );
}
