import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { useOnScreen } from "data/functions";
import FindBlock from "pages/services/FindBlock";
import { FolderPage } from "pages/services/FolderPage";
import ServiceTitle from "pages/services/ServiceTitle";
import { ServicesButtons } from "pages/services/ServicesButtons";
import React from "react";

export default function ServicesMain(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <ServiceTitle />

        <Box sx={{ overflow: "auto", position: "relative" }}>
          {dataObj.includeBeta && <FindBlock />}
          <FolderPage />
        </Box>
        {/* <ServicesButtons /> */}
      </Box>
    </React.Fragment>
  );
}
