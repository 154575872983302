import React from "react";

import { Box, Divider, Typography } from "@mui/material";
import { GetData } from "contexts/DataContext";
import {
  dateToString,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
} from "data/functions";

export default function AccrueInfo(props) {
  const [accrueArr, setAccrueArr] = React.useState([]);
  const { dataObj } = GetData();
  React.useEffect(() => {
    if (!isIdentical(accrueArr, dataObj?.creditAccrue)) {
      setAccrueArr(dataObj?.creditAccrue);
      props?.setParentArray(dataObj?.creditAccrue);
    }
    // eslint-disable-next-line
  }, [dataObj?.creditAccrue]);
  return (
    <Box>
      {!isObjectEmpty(accrueArr) &&
        accrueArr.map((element, index) => (
          <Box key={index} sx={{ padding: "2px 16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
              >
                {element?.activeCredit} бонусов
              </Typography>
              <Typography
                align="right"
                variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
              >
                {parseRFC3339(element?.lifePeriod?.expireDate) <
                new Date("3990-12-31T21:00:00Z")
                  ? "до " +
                    dateToString(parseRFC3339(element?.lifePeriod?.expireDate))
                  : "бессрочно"}
              </Typography>
            </Box>
            <Divider />
          </Box>
        ))}
    </Box>
  );
}
