import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { GetData } from "contexts/DataContext";

import React, { useState } from "react";

export default function FindBlock(props) {
  const { dataObj } = GetData();
  const [value, setValue] = useState("");
  return (
    <React.Fragment>
      <TextField
        InputProps={{
          type: "search",
          readOnly: props.logined ? props.logined : false,
          disableUnderline: props.logined ? props.logined : false,
          style: {
            fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        fullWidth={true}
        mb={2}
        mt={2}
        name="search"
        onChange={(e) => setValue(e.target.value)}
        sx={{
          minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "48%",
          margin: "0.3rem 0",
          padding: "0 12px ",
        }}
        type="text"
        value={value}
        variant="standard"
      />
    </React.Fragment>
  );
}
