import { ArrowDropDown } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { DrawerBlock } from "components/DrawerBlock";
import { GetData } from "contexts/DataContext";
import CarListItem from "pages/cars/list/CarListItem";
import React from "react";

export function CarBlock() {
  const { dataObj, setDataObj } = GetData();
  const [openFilter, setOpenFilter] = React.useState(false);
  return (
    <React.Fragment>
      <Box onClick={() => setOpenFilter(true)} sx={{ position: "relative" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: "30%",
            right: "0%",
            padding: 0,
            // fill: "rgba(0, 0, 0, 0.54)",
          }}
        >
          <ArrowDropDown />
        </IconButton>

        <CarListItem el={dataObj?.usedCar} />
      </Box>

      <DrawerBlock
        component={<CarListItem />}
        itemArray={dataObj.carArr || []}
        onClickItemFunc={(el = "") => {
          setOpenFilter(false);
          setDataObj(el, "usedCar");
        }}
        onCloseFunc={() => {
          setOpenFilter(false);
        }}
        open={openFilter}
        showFull={false}
      />
    </React.Fragment>
  );
}
