import React, { useState } from "react";

import { Description } from "@mui/icons-material";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { GarantDialog } from "components/GarantDialog";
import { MapBlock } from "components/MapBlock";
import { PageTitle } from "components/PageTitle";
import { ReviewDialog } from "components/ReviewDialog";
import { OrderReadQuery } from "components/gql/queris/GetOrderInfo";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { dateToString, parseRFC3339 } from "data/functions";
import {
  findInArray,
  getOrderDate,
  isIdentical,
  isObjectEmpty,
  isOrderStatusActive,
  translateOrderStatus,
  translateOrderType,
} from "data/functions";
import { constants } from "data/globals";
import { showRoute } from "data/mobapp_functions";
import { BoxFlexRow } from "data/styleGlobals";
import { ProfileTable } from "pages/orders/info/ProfileTable";
import { useLocation, useParams } from "react-router-dom";

function ParamRow(props) {
  return (
    <React.Fragment>
      <BoxFlexRow>
        <Typography>{props?.title}</Typography>
        <Typography>{props?.subTitle}</Typography>
      </BoxFlexRow>
      <Divider />
    </React.Fragment>
  );
}
export default function OrderProfile() {
  const [openReview, setOpenReview] = useState(false);
  const [openGarant, setOpenGarant] = useState(false);
  const { setAlert } = GetAlertData();
  let location = useLocation();
  const { dataObj } = GetData();
  const params = useParams();
  const { cInfo } = GetCInfo();
  const orderID = location.state?.orderID || params.id;
  const [orderGQL] = OrderReadQuery();
  const [docItem, setDocItem] = useState(
    findInArray(dataObj?.orders, orderID, "orderID") || {},
  );
  const department = findInArray(
    cInfo?.departments,
    docItem?.departmentID,
    "ID",
  );

  React.useEffect(() => {
    let val = findInArray(dataObj?.orders, orderID, "orderID");
    if (val && !isIdentical(docItem, val)) setDocItem(val);
    // eslint-disable-next-line
  }, [dataObj?.orders]);

  React.useEffect(() => {
    let val = findInArray(dataObj?.orders, orderID, "orderID");
    if (val && !isIdentical(docItem, val)) setDocItem(val);
    if (!val) {
      orderGQL({
        variables: { orderId: orderID },
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {/* <Paper> */}
      <PageTitle
        title={translateOrderType(docItem.type)}
        title2={getOrderDate(docItem)}
      />
      {/* </Paper> */}

      <Box
        sx={{
          margin: "0px 8px 8px 8px",
          // boxShadow: "0px 0px 10px 2px rgb(0 0 0 / 20%)",
          padding: 4,
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          // opacity:
          //   orderGQL?.loading && isObjectEmpty(docItem) ? "0.1" : undefined,
        }}
      >
        <Box sx={{ margin: "0rem 1rem 1rem 1rem", overflow: "auto" }}>
          {/* <PageTitle
            title={translateOrderType(docItem.type)}
            title2={getOrderDate(docItem)}
          /> */}
          {translateOrderType(docItem?.type) !== constants.ruTypeZR &&
            docItem?.status && (
              <ParamRow
                subTitle={translateOrderStatus(docItem?.status)}
                title="Статус"
              />
            )}

          {department?.name && (
            <ParamRow subTitle={department?.name} title="Филиал" />
          )}
          {department?.adress && (
            <ParamRow subTitle={department?.adress} title="Адрес" />
          )}

          {/* {docItem?.masterName && (
            <ParamRow subTitle={docItem?.masterName} title="Мастер" />
          )} */}

          {(docItem?.mileage || docItem?.mileage > 0) && (
            <ParamRow
              subTitle={docItem?.mileage + " км"}
              title="Зафиксированный пробег"
            />
          )}

          {!isObjectEmpty(parseRFC3339(docItem?.planDate)) && (
            <React.Fragment>
              {translateOrderType(docItem?.type) === constants.ruTypeZN && (
                <ParamRow
                  subTitle={dateToString(parseRFC3339(docItem?.planDate), true)}
                  title={"Время выдачи"}
                />
              )}
              {translateOrderType(docItem?.type) === constants.ruTypeZP && (
                <ParamRow
                  subTitle={dateToString(
                    parseRFC3339(docItem?.planDate),
                    false,
                  )}
                  title={"Срок поставки"}
                />
              )}
              {translateOrderType(docItem?.type) === constants.ruTypeZR && (
                <ParamRow
                  subTitle={dateToString(
                    parseRFC3339(docItem?.planDate),
                    false,
                    true,
                  )}
                  title={"Время"}
                />
              )}
            </React.Fragment>
          )}
          <br />
          <ProfileTable docItem={docItem} />
          {parseInt(docItem?.docSum?.total) > 0 &&
            translateOrderType(docItem?.type) !== constants.ruTypeZR && (
              <ParamRow subTitle={docItem?.docSum?.total + "₽"} title="Итого" />
            )}
          {!isObjectEmpty(department) && (
            <MapBlock array={[...[], department]} disableCityButton={true} />
          )}

          {isOrderStatusActive(docItem?.status) && (
            <React.Fragment>
              {!isObjectEmpty(department) &&
                translateOrderType(docItem?.type) === constants.ruTypeZR && (
                  <Button
                    fullWidth
                    onClick={() => {
                      if (
                        (dataObj?.platform === "Android" ||
                          dataObj?.platform === "iPhone" ||
                          dataObj?.platform === "iPad" ||
                          dataObj?.platform === "iPod") &&
                        department?.locationLatitude &&
                        department?.locationLongitude
                      )
                        showRoute(
                          department?.locationLatitude,
                          department?.locationLongitude,
                        );
                      else {
                        let URL = "https://yandex.ru/maps/";
                        if (dataObj?.locationParam) {
                          URL =
                            URL +
                            "?rtext=" +
                            dataObj?.locationParam?.location_longitude +
                            "," +
                            dataObj?.locationParam?.location_latitude +
                            "~" +
                            department?.locationLongitude +
                            "," +
                            department?.locationLatitude +
                            "&rtt=auto";
                        } else {
                          URL =
                            URL +
                            "?whatshere[point]=" +
                            department?.locationLongitude +
                            "," +
                            department?.locationLatitude +
                            "&whatshere[zoom]=17";
                        }
                        window.open(URL, "_blank");
                      }
                    }}
                    sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
                    variant="outlined"
                    // loadingPosition="start"
                  >
                    Маршрут проезда
                  </Button>
                )}
              {translateOrderType(docItem?.type) === constants.ruTypeZR && (
                <Button
                  fullWidth
                  onClick={() =>
                    setAlert(
                      "Не удалось изменить! \nОбратитесь в автосервис",
                      "warning",
                    )
                  }
                  sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
                  variant="outlined"
                  // loadingPosition="start"
                >
                  Перенести запись
                </Button>
              )}

              {translateOrderType(docItem?.type) !== constants.ruTypeZN && (
                <Button
                  color="error"
                  fullWidth
                  onClick={() =>
                    setAlert(
                      "Не удалось отменить! \nОбратитесь в автосервис",
                      "warning",
                    )
                  }
                  sx={{
                    margin: "0.4rem 0px 0.4rem 0px",
                    textDecoration: "underline",
                  }}
                  // variant="outlined"
                  // loadingPosition="start"
                >
                  Отменить
                </Button>
              )}
            </React.Fragment>
          )}
          {!isOrderStatusActive(docItem?.status) && (
            <Button
              fullWidth
              onClick={() => setOpenReview(true)}
              sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
              variant="outlined"
              // loadingPosition="start"
            >
              Отправить отзыв
            </Button>
          )}
          {!isOrderStatusActive(docItem?.status) && (
            <Button
              fullWidth
              onClick={() => setOpenGarant(true)}
              sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
              variant="outlined"
              // loadingPosition="start"
            >
              Запрос по гарантии
            </Button>
          )}
        </Box>
        <ReviewDialog
          open={openReview}
          setOpen={setOpenReview}
          title={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Description color="dimblue" />
              {translateOrderType(docItem?.type) + " №" + docItem?.docNumber}
            </Box>
          }
        />
        <GarantDialog
          item={docItem}
          open={openGarant}
          setOpen={setOpenGarant}
        />
      </Box>
    </React.Fragment>
  );
}
