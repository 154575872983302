import React, { useEffect, useState } from "react";

import { Apartment } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import ParamBlock from "components/ParamBlock";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { findInArray, isIdentical, isObjectEmpty } from "data/functions";
import { showRoute } from "data/mobapp_functions";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ReviewsDialog } from "pages/departments/ReviewDialog";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function DepartmentProfile(props) {
  let location = useLocation();
  const params = useParams();
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const departmentID = location.state?.departmenID || params.id;
  const [el, setEl] = useState(
    findInArray(cInfo?.departments, departmentID, "ID") || {},
  );

  useEffect(() => {
    let val = findInArray(cInfo?.departments, departmentID, "ID");
    if (!isIdentical(el, val)) {
      setEl(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.departments]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Контакты"} />
        <Typography
          sx={{
            padding: "0.4rem",
            textAlign: dataObj?.deviceWidth?.isMobile ? "center" : "start",
          }}
          variant={dataObj?.deviceWidth?.isMobile ? "h5" : "h4"}
        >
          {el.name}
        </Typography>
        <Box sx={{ overflow: "auto" }}>
          <DepartmentImage
            images={
              !isObjectEmpty(el?.images)
                ? el?.images
                : [
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-1.jpg",
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-2.jpg",
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-3.jpg",
                  ]
            }
            title={el?.title}
          />
          <Box sx={{ margin: "1.5rem" }}>
            {el?.workTime && (
              <ParamBlock text={el?.workTime} title={"Время работы"} />
            )}
            {el?.phone && <ParamBlock text={el?.phone} title={"Телефон"} />}
            {el?.adress && <ParamBlock text={el?.adress} title={"Адрес"} />}
          </Box>
          <DepartmentButtons item={el} />
        </Box>
      </Box>
    </React.Fragment>
  );
}

function DepartmentImage(props) {
  const { dataObj } = GetData();

  return (
    <Box
      sx={{
        textAlign: "center",
        //   padding: 0,
        //   margin: 0,
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",

        //   maxHeight: "200px",
        //   overflow: "hidden",
      }}
    >
      {!isObjectEmpty(props?.images) && (
        <Swiper
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel={{
            releaseOnEdges: true,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          slidesPerView={
            dataObj?.deviceWidth?.isMobile || dataObj?.deviceWidth?.isPad
              ? 1
              : 3
          }
          spaceBetween={16}
        >
          {(props?.images ? props?.images : []).map((el, index) => (
            <SwiperSlide key={index}>
              <Box
                key={index}
                sx={{
                  margin: "0.4rem 0",

                  height: dataObj?.deviceWidth?.isMobile
                    ? "calc(68vw - 0.66rem)"
                    : dataObj?.deviceWidth?.isPad
                      ? "calc(50vh - 0.66rem)"
                      : "50vh",
                  width: "100%",
                  backgroundImage: "url(" + el + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isObjectEmpty(props?.images) && (
        <Apartment sx={{ height: "7rem", width: "7rem" }} />
      )}
    </Box>
  );
}

function DepartmentButtons(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { dataObj } = GetData();
  const { cInfo, setCInfo } = GetCInfo();
  const handleSubmit = async (item, state, index) => {
    if (!isObjectEmpty(cInfo?.regions) && props?.item?.region) {
      let find = cInfo?.regions.find(
        (reg) => reg?.name === props?.item?.region,
      );
      await setCInfo(find, "preferedRegion");
    }
    navigate("/request", { state: { departmentItem: props?.item } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        margin: "0.5rem",
        boxShadow: "none",
        flexWrap: "wrap",
        gap: "0.2rem",
      }}
    >
      <Button disableRipple onClick={handleSubmit} variant="outlined">
        Записаться
      </Button>
      {dataObj.includeBeta && (
        <Button
          disableRipple
          // onClick={handleSubmit}
          onClick={(e) => {
            if (props?.item?.reviews && props?.item?.reviews?.length > 0)
              setOpen(true);
            else {
              alert("Отзывов нет..");
            }
          }}
          variant="outlined"
        >
          Отзывы
        </Button>
      )}

      <MapButton item={props?.item} />
      <ReviewsDialog
        reviews={props?.item?.reviews}
        setOpen={setOpen}
        status={open}
      />
    </Box>
  );
}

export function MapButton(props) {
  const { dataObj } = GetData();

  return (
    <Button
      disableRipple
      onClick={(e) => {
        if (
          (dataObj?.platform === "Android" ||
            dataObj?.platform === "iPhone" ||
            dataObj?.platform === "iPad" ||
            dataObj?.platform === "iPod") &&
          props?.item?.locationLatitude &&
          props?.item?.locationLongitude
        )
          showRoute(
            props?.item?.locationLatitude,
            props?.item?.locationLongitude,
          );
        else {
          let URL = "https://yandex.ru/maps/";
          if (dataObj?.locationParam) {
            URL =
              URL +
              "?rtext=" +
              dataObj?.locationParam?.location_longitude +
              "," +
              dataObj?.locationParam?.location_latitude +
              "~" +
              props.item?.locationLongitude +
              "," +
              props.item?.locationLatitude +
              "&rtt=auto";
          } else {
            URL =
              URL +
              "?whatshere[point]=" +
              props.item?.locationLongitude +
              "," +
              props.item?.locationLatitude +
              "&whatshere[zoom]=17";
          }
          window.open(URL, "_blank");
        }
      }}
      variant="outlined"
    >
      Маршрут
    </Button>
  );
}
