import styled from "@emotion/styled";
import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Drawer as MuiDrawer, ListItemButton } from "@mui/material";

import Footer from "components/sidebar/SidebarFooter";
import SidebarNav from "components/sidebar/SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  min-height: 56px;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme?.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const Sidebar = ({ items, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Brand component={NavLink} to="/">
            <Box ml={1}>5S LINK</Box>
          </Brand>
          <SidebarNav items={items} />
        </Box>
        {/* <SidebarBasket /> */}

        <Box>
          <Footer />
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
