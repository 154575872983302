import { Box, Card, CardContent, CardMedia } from "@mui/material";
import { GetData } from "contexts/DataContext";

export const FolderBlock = (props) => {
  const { dataObj } = GetData();
  return (
    <Box
      sx={{
        margin: "0.2rem",

        width: dataObj?.deviceWidth?.isMobile ? undefined : "400px",
        padding: props?.thereIsEl ? "2px 6px" : undefined,
        flex: "auto",
      }}
    >
      <Card
        sx={{
          position: "relative",
          borderLeft: props?.borderLeft ? props?.borderLeft : undefined,
          // border: props?.thereIsEl ? undefined : "1px solid black",
        }}
      >
        {props?.image ? (
          <CardMedia
            component="img"
            height="150"
            image={props?.image}
            title={props?.title}
          />
        ) : null}
        <CardContent sx={{ padding: "4px 12px !important" }}>
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              fontWeight: props?.thereIsEl ? 400 : 500,
              fontSize: props?.thereIsEl ? undefined : "1.16rem",
              marginBottom: !props?.description ? 0 : undefined,
            }}
            variant="h6"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.iconElement}
              {props?.title}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.price}
              {props?.deleteComponent}
            </Box>
          </Box>

          {props?.description && (
            <Box
              // gutterBottom
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.description}
            </Box>
          )}
          {props?.sDescription && (
            <Box
              // gutterBottom
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.sDescription}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
