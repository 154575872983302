import { useMutation } from "@apollo/client";

import { ADD_CAR } from "components/gql/gql_queries";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty } from "data/functions";

export function AddCarMutation() {
  const { setAlert } = GetAlertData();
  const [mutation, variables] = useMutation(ADD_CAR, {
    onCompleted: (data) => {
      if (data?.carAdd?.code === 200) {
        consoleBeauty("Успешно добавлен автомобиль", "GraphQL", "darkgreen");
        setAlert("Автомобиль добавлен!");
      } else {
        setAlert("AddCar: Не удалось добавить автомобиль", "warning");
        consoleBeauty(data?.carAdd?.message, "GraphQL", "red");
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          data?.carAdd?.message,
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
      setAlert("Не добавить автомобиль. \nПопробуйте позже.", "error");
    },
  });
  return [mutation, variables];
}
