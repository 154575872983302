import { useQuery } from "@apollo/client";
import { GET_CREDITS } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, isIdentical, isObjectEmpty } from "data/functions";

export function GetCreditsQuery() {
  const { token } = GetAuthData();
  const { dataObj, setDataObj } = GetData();
  const query = useQuery(GET_CREDITS, {
    // fetchPolicy: "no-cache",
    errorPolicy: "all",
    skip: !dataObj?.clientID || !token,
    onCompleted: (data) => {
      if (isObjectEmpty(data?.creditBalance)) {
        setDataObj(0, "creditBalance");
        setDataObj([], "creditAccrue");
        setDataObj([], "presentAccrue");
      } else {
        if (
          !isIdentical(
            data?.creditBalance?.activeCreditsTotal,
            dataObj?.creditBalance,
          ) ||
          !isIdentical(data?.creditBalance?.presents, dataObj?.presentAccrue) ||
          !isIdentical(data?.creditBalance?.credits, dataObj?.creditAccrue)
        ) {
          consoleBeauty(
            "Получена информация по бонусам",
            "GraphQL",
            "darkgreen",
          );
          if (
            !isIdentical(
              data?.creditBalance?.activeCreditsTotal,
              dataObj?.creditBalance,
            )
          ) {
            setDataObj(data.creditBalance.activeCreditsTotal, "creditBalance");
          }
          if (
            !isIdentical(data?.creditBalance?.credits, dataObj?.creditAccrue)
          ) {
            setDataObj(data.creditBalance.credits, "creditAccrue");
          }
          if (
            !isIdentical(data?.creditBalance?.presents, dataObj?.presentAccrue)
          ) {
            setDataObj(data.creditBalance.presents, "presentAccrue");
          }
        }
      }
    },
    onError: (e) => {
      setDataObj(0, "creditBalance");
      setDataObj([], "creditAccrue");
      setDataObj([], "presentAccrue");
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "Credits: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return query;
}
