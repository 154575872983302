import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import {
  Box,
  Card,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import {
  dateToString,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
} from "data/functions";
import React, { useEffect, useState } from "react";

export default function HistoryInfo(props) {
  const [historyArr, setHistoryArr] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const { dataObj } = GetData();

  useEffect(() => {
    if (!isObjectEmpty(dataObj?.creditHistory)) {
      // console.log("Изменилась машина");
      let arr = [];
      let dataObjTemp = [...dataObj?.creditHistory].sort(
        (a, b) => a.date - b.date,
      );
      dataObjTemp.forEach((el) => {
        let newDate = dateToString(parseRFC3339(el.date, true));
        arr = [
          ...arr,
          { activeCredits: el.activeCredits, date: newDate ? newDate : "" },
        ];
      });

      if (!isIdentical(historyArr, arr)) {
        props?.setParentArray(arr);
        setHistoryArr(arr);
      }
    } else {
      setHistoryArr([]);
    }
    // eslint-disable-next-line
  }, [dataObj?.creditHistory]);

  return (
    <React.Fragment>
      {!isObjectEmpty(historyArr) && (
        <Box>
          <Typography align="center" sx={{ padding: "1rem" }} variant="h6">
            История бонусов
          </Typography>

          <Card sx={{ margin: "0rem 3rem", background: "transparent" }}>
            <Collapse
              collapsedSize={45}
              in={expanded}
              // sx={{ minHeight: "min-content" }}
            >
              <Box>
                {historyArr.map((element, index) => (
                  <Box key={index} sx={{ padding: "0.1rem 0.5rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant={
                          dataObj?.deviceWidth?.isMobile ? "body2" : "body1"
                        }
                      >
                        {element?.date}
                      </Typography>

                      <Typography
                        align="right"
                        variant={
                          dataObj?.deviceWidth?.isMobile ? "body2" : "body1"
                        }
                      >
                        {element?.activeCredits > 0
                          ? "+" + element?.activeCredits
                          : element?.activeCredits}{" "}
                        Б
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                ))}
              </Box>
            </Collapse>
            <Box
              onClick={() => setExpanded(!expanded)}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <IconButton>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </Card>
        </Box>
      )}
    </React.Fragment>
  );
}
