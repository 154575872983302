import React, { useState } from "react";

import { Box, Button } from "@mui/material";

import { Call, Sms, Telegram } from "@mui/icons-material/";
import { LoadingButton } from "@mui/lab";
import { CompanyErrorPage } from "components/CompanyError";
import { GetCInfo } from "contexts/CompanyContext";
import { isIdentical, isObjectEmpty } from "data/functions";

export const AuthMethodButtons = (props) => {
  const { cInfo } = GetCInfo();
  const [channels, setChannels] = useState([]);
  React.useEffect(() => {
    if (cInfo?.channels) {
      let arr = [];
      ["Telegram", "Sms", "Phone"].forEach((element) => {
        let find = (cInfo?.channels ?? []).find((el) => {
          if (el?.name === element) return true;
          else return false;
        });
        if (find) arr = [...arr, find];
      });
      if (!isIdentical(channels, arr)) {
        setChannels(arr);
      }
      props?.setError(isObjectEmpty(arr));
    }
  }, [cInfo?.channels]);

  return (
    <React.Fragment>
      {!props?.method && (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              margin: "2rem 0px",
            }}
          >
            {(channels ?? []).map((c, i) => (
              <LoadingButton
                color={props?.flowVars?.error ? "error" : "primary"}
                disabled={!c?.active}
                key={i}
                loading={props?.flowVars?.loading}
                onClick={() => {
                  props?.setMethod(c?.name.toLowerCase());
                }}
                startIcon={
                  c?.name === "Telegram" ? (
                    <Telegram />
                  ) : c?.name === "Sms" ? (
                    <Sms />
                  ) : c?.name === "Phone" ? (
                    <Call />
                  ) : undefined
                }
                variant="outlined"
              >
                {c?.name === "Sms"
                  ? "Sms"
                  : c?.name === "Phone"
                    ? "Звонок"
                    : c?.name}
              </LoadingButton>
            ))}
            {/* <LoadingButton
              color={props?.flowVars?.error ? "error" : "primary"}
              loading={props?.flowVars?.loading}
              onClick={() => {
                props?.setMethod("telegram");
              }}
              startIcon={<Telegram />}
              variant="outlined"
            >
              Telegram
            </LoadingButton>
            <Button
              color={props?.flowVars?.error ? "error" : "primary"}
              onClick={() => props?.setMethod("sms")}
              startIcon={<Sms />}
              variant="outlined"
            >
              Sms
            </Button>
            <Button
              color={props?.flowVars?.error ? "error" : "primary"}
              onClick={() => props?.setMethod("phone")}
              startIcon={<Call />}
              variant="outlined"
            >
              Call
            </Button> */}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
