import { Box } from "@mui/material";
import React from "react";

import { DirectionsCarFilled } from "@mui/icons-material/";
import { GetData } from "contexts/DataContext";
import { getCarImage } from "data/functions";
import { CarLeftSVG } from "data/styleGlobals";

export default function ProfileImage(props) {
  const { dataObj } = GetData();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {props?.car?.car?.model?.imageUrl ? (
        <img
          alt={props?.car?.car?.vin}
          src={props?.car?.car?.model?.imageUrl}
          style={{ overflow: "hidden", width: "800px" }}
        />
      ) : (
        <CarLeftSVG
          // color="dimblue"
          style={{
            // height: dataObj?.deviceWidth?.isMobile ? "6rem" : "12rem",
            // width: dataObj?.deviceWidth?.isMobile ? "6rem" : "12rem",
            opacity: "0.1",
            padding: "12px",
          }}
        />
      )}
    </Box>
  );
}
