import { useLazyQuery } from "@apollo/client";
import { CAR_RECOMMENDATIONS_LIST } from "components/gql/gql_queries";

import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty } from "data/functions";

export function CarRecsQuery() {
  const { token } = GetAuthData();
  const [query, variables] = useLazyQuery(CAR_RECOMMENDATIONS_LIST, {
    fetchPolicy: "no-cache",
    skip: !token,
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );
      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [query, variables];
}
