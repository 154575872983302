import { useQuery } from "@apollo/client";
import { CREDIT_HISTORY } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, isIdentical, isObjectEmpty } from "data/functions";

export function GetCreditsHistoryQuery() {
  const { dataObj, setDataObj } = GetData();
  const { token } = GetAuthData();
  const query = useQuery(CREDIT_HISTORY, {
    skip: !token,
    onCompleted: (data) => {
      if (isObjectEmpty(data?.creditHistory)) {
        setDataObj([], "creditHistory");
      } else if (!isIdentical(data?.creditHistory, dataObj?.creditHistory)) {
        consoleBeauty(
          "Запрос истории по бонусам. Успех.",
          "GraphQL",
          "darkgreen",
        );
        setDataObj(data.creditHistory, "creditHistory");
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return query;
}
